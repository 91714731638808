<template>
  <div class="mind-container">
    <div class="empty-box" v-show="!isData">
      <empty :name="'暂无数据'"></empty>
    </div>
    <div class="container-box" v-if="isData">
      <div class="operation">
        <img
          @dragstart.prevent
          src="@/assets/img/magnify.png"
          alt=""
          @click="toMagnify"
          v-show="scaleNum != 1"
        />
        <img
          @dragstart.prevent
          src="@/assets/img/magnify-stop.png"
          alt=""
          v-show="scaleNum == 1"
          style="cursor: not-allowed"
        />
        <div class="line"></div>
        <img
          @dragstart.prevent
          src="@/assets/img/shrink.png"
          alt=""
          @click="toShrink"
          v-show="scaleNum != 0.6"
        />
        <img
          @dragstart.prevent
          src="@/assets/img/shrink-stop.png"
          alt=""
          v-show="scaleNum == 0.6"
          style="cursor: not-allowed"
        />
        <div class="line"></div>
        <img
          @dragstart.prevent
          src="@/assets/img/refresh.png"
          alt=""
          @click="toRefresh"
        />
      </div>
      <div class="all-box">
        <div class="all-num">
          <div class="all-name">项目总数（个）</div>
          <div class="all-item">{{ allNum }}</div>
        </div>
        <div class="all-num">
          <div class="all-name">中标总金额（亿元）</div>
          <div class="all-item">{{ allPrice }}</div>
        </div>
      </div>
      <div class="first-box" v-drag id="tenderee">
        <div class="tenderee" :style="{ top: tendereeTop - 25 + 'px' }">
          <el-tooltip effect="light" :content="tenderee" placement="bottom">
            <div class="text-ellipsis">{{ tenderee }}</div>
          </el-tooltip>
          <div class="tenderee-line"></div>
        </div>
        <div class="second-box">
          <div
            class="second-line"
            :style="{ top: secondLinTop + 'px', height: secondLinHeight + 'px' }"
          ></div>
          <div class="second-item">
            <div class="second-name" id="winning">
              <div>中标单位</div>
              <div class="second-name-line"></div>
            </div>
            <div class="third-box">
              <template v-if="!winningShowType">
                <div
                  class="third-name"
                  v-for="(item, i) in winningList.slice(0, 5)"
                  :key="i"
                >
                  <el-tooltip effect="light" :content="item.bidWinner" placement="bottom">
                    <div class="name-box">{{ item.bidWinner }}</div>
                  </el-tooltip>
                  <div class="third-name-line third-name-line-end"></div>
                </div>
              </template>
              <template v-if="winningShowType">
                <div class="third-name" v-for="(item, i) in winningList" :key="i">
                  <el-tooltip effect="light" :content="item.bidWinner" placement="bottom">
                    <div class="name-box">{{ item.bidWinner }}</div>
                  </el-tooltip>
                  <div class="third-name-line"></div>
                </div>
              </template>
              <div
                class="third-name"
                v-if="!winningShowType && winningList.length > 5"
                style="cursor: pointer"
                @click="opencloseWinning(1)"
              >
                展开（{{ winningList.length - 5 }}）
                <span
                  class="iconfont icon-zengjia"
                  style="font-size: 18px; margin-left: 20px; font-weight: bold"
                ></span>
              </div>
              <div
                class="third-name"
                v-if="winningShowType"
                style="cursor: pointer"
                @click="opencloseWinning(2)"
              >
                收起
                <span
                  class="iconfont icon-jianshao"
                  style="font-size: 18px; margin-left: 20px; font-weight: bold"
                ></span>
              </div>
              <div class="third-line" v-show="winningList.length != 1"></div>
              <div class="third-line-one" v-show="winningList.length == 1"></div>
            </div>
            <div
              class="winning-table winning-table-winning"
              :class="winningList.length < 6 ? 'winning-table-close' : ''"
            >
              <el-table
                v-if="!winningShowType"
                :data="winningList.slice(0, 5)"
                header-cell-class-name="table_header"
                style="width: 100%"
                @sort-change="sortWinningChange"
              >
                <el-table-column
                  align="center"
                  prop="projectAllCount"
                  label="项目数量（个）"
                  sortable="custom"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="projectAllPrice"
                  label="中标金额（亿元）"
                  sortable="custom"
                >
                </el-table-column>
              </el-table>
              <el-table
                v-if="winningShowType"
                :data="winningList"
                header-cell-class-name="table_header"
                style="width: 100%"
                @sort-change="sortWinningChange"
              >
                <el-table-column
                  align="center"
                  prop="projectAllCount"
                  label="项目数量（个）"
                  sortable="custom"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="projectAllPrice"
                  label="中标金额（亿元）"
                  sortable="custom"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="second-item">
            <div class="second-name second-area" id="area">
              <div>地区</div>
              <div class="second-name-line"></div>
            </div>
            <div class="third-box">
              <template v-if="!provinceShowType">
                <div
                  class="third-province-name"
                  v-for="(item, i) in areaList.slice(0, 5)"
                  :key="i"
                >
                  <el-tooltip effect="light" :content="item.province" placement="bottom">
                    <div class="name-box">{{ item.province }}</div>
                  </el-tooltip>
                  <span
                    v-show="!item.open"
                    class="iconfont icon-zengjia"
                    style="
                      font-size: 18px;
                      margin-left: 20px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                    @click="openCloseCity(1, item, i)"
                  ></span>
                  <span
                    v-show="item.open"
                    class="iconfont icon-jianshao"
                    style="
                      font-size: 18px;
                      margin-left: 20px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                    @click="openCloseCity(2, item, i)"
                  ></span>
                  <div class="third-name-line third-name-line-end"></div>
                  <div class="third-right-line" v-show="i == provinceIndex"></div>
                </div>
              </template>
              <template v-if="provinceShowType">
                <div class="third-province-name" v-for="(item, i) in areaList" :key="i">
                  <el-tooltip effect="light" :content="item.province" placement="bottom">
                    <div class="name-box">{{ item.province }}</div>
                  </el-tooltip>
                  <span
                    v-show="!item.open"
                    class="iconfont icon-zengjia"
                    style="
                      font-size: 18px;
                      margin-left: 20px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                    @click="openCloseCity(1, item, i)"
                  ></span>
                  <span
                    v-show="item.open"
                    class="iconfont icon-jianshao"
                    style="
                      font-size: 18px;
                      margin-left: 20px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                    @click="openCloseCity(2, item, i)"
                  ></span>
                  <div class="third-name-line"></div>
                  <div class="third-right-line" v-show="i == provinceIndex"></div>
                </div>
              </template>
              <div
                class="third-province-name"
                v-if="!provinceShowType && areaList.length > 5"
                @click="opencloseProvince(1)"
                style="cursor: pointer"
              >
                <el-tooltip
                  effect="light"
                  :content="'展开（' + (areaList.length - 5) + '）'"
                  placement="bottom"
                >
                  <span class="text-ellipsis" style="width: 150px">
                    展开（{{ areaList.length - 5 }}）
                  </span>
                </el-tooltip>
                <span
                  class="iconfont icon-zengjia"
                  style="font-size: 18px; margin-left: 20px; font-weight: bold"
                ></span>
              </div>
              <div
                class="third-province-name"
                v-if="provinceShowType"
                @click="opencloseProvince(2)"
                style="cursor: pointer"
              >
                收起
                <span
                  class="iconfont icon-jianshao"
                  style="font-size: 18px; margin-left: 20px; font-weight: bold"
                ></span>
              </div>
              <div class="third-line" v-show="areaList.length != 1"></div>
              <div class="third-line-one" v-show="areaList.length == 1"></div>
            </div>
            <div
              class="winning-table winning-table-area area-table"
              :class="areaList.length < 6 ? 'winning-table-close' : ''"
              v-if="cityList.length == 0"
            >
              <el-table
                v-if="!provinceShowType"
                :data="areaList.slice(0, 5)"
                header-cell-class-name="table_header"
                style="width: 100%"
                @sort-change="sortProvinceChange"
              >
                <el-table-column
                  align="center"
                  prop="projectAllCount"
                  label="项目数量（个）"
                  sortable="custom"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="projectAllPrice"
                  label="中标金额（亿元）"
                  sortable="custom"
                >
                </el-table-column>
              </el-table>
              <el-table
                v-if="provinceShowType"
                :data="areaList"
                header-cell-class-name="table_header"
                style="width: 100%"
                @sort-change="sortProvinceChange"
              >
                <el-table-column
                  align="center"
                  prop="projectAllCount"
                  label="项目数量（个）"
                  sortable="custom"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="projectAllPrice"
                  label="中标金额（亿元）"
                  sortable="custom"
                >
                </el-table-column>
              </el-table>
            </div>
            <div
              class="city-container"
              :style="{
                height: getCityHeight() + 'px',
              }"
            >
              <div
                class="city-box"
                :style="{ marginTop: getCityTop() + 'px' }"
                v-if="cityList.length != 0"
              >
                <div class="city-name" v-for="(item, i) in cityList" :key="i">
                  <el-tooltip effect="light" :content="item.city" placement="bottom">
                    <div class="name-box">{{ item.city }}</div>
                  </el-tooltip>
                  <span
                    v-show="!item.open"
                    class="iconfont icon-zengjia"
                    style="
                      font-size: 18px;
                      margin-left: 20px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                    @click="openCloseCounty(1, item, i)"
                  ></span>
                  <span
                    v-show="item.open"
                    class="iconfont icon-jianshao"
                    style="
                      font-size: 18px;
                      margin-left: 20px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                    @click="openCloseCounty(2, item, i)"
                  ></span>
                  <div class="city-name-line"></div>
                  <div
                    class="city-right-line"
                    v-show="i == cityIndex && countyList.length != 0"
                  ></div>
                </div>
                <div class="left-line" v-show="cityList.length != 1"></div>
                <div class="left-line-one" v-show="cityList.length == 1"></div>
              </div>
            </div>
            <div
              :style="{
                height: getCityHeight() + 'px',
              }"
            >
              <div
                class="winning-table area-table"
                :style="{ marginTop: getCityTop() - 60 + 'px' }"
                v-if="cityList.length != 0 && countyList.length == 0"
              >
                <el-table
                  :data="cityList"
                  header-cell-class-name="table_header"
                  style="width: 100%"
                  @sort-change="sortCityChange"
                >
                  <el-table-column
                    align="center"
                    prop="projectAllCount"
                    label="项目数量（个）"
                    sortable="custom"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="projectAllPrice"
                    label="中标金额（亿元）"
                    sortable="custom"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div
              class="county-container"
              :style="{
                height: getCountyHeight() + 'px',
              }"
            >
              <div
                class="county-box"
                :style="{ marginTop: getCountyTop() + 'px' }"
                v-if="countyList.length != 0"
              >
                <div class="county-name" v-for="(item, i) in countyList" :key="i">
                  <el-tooltip
                    effect="light"
                    :content="item.projectCounty"
                    placement="bottom"
                  >
                    <div class="name-box">
                      {{ item.projectCounty }}
                    </div>
                  </el-tooltip>
                  <div class="county-name-line"></div>
                </div>
                <div class="left-line" v-show="countyList.length != 1"></div>
                <div class="left-line-one" v-show="countyList.length == 1"></div>
              </div>
            </div>
            <div
              :style="{
                height: getCountyHeight() + 'px',
              }"
            >
              <div
                class="winning-table area-table"
                :style="{ marginTop: getCountyTop() - 60 + 'px' }"
                v-if="cityList.length != 0 && countyList.length != 0"
              >
                <el-table
                  :data="countyList"
                  header-cell-class-name="table_header"
                  style="width: 100%"
                  @sort-change="sortCountyChange"
                >
                  <el-table-column
                    align="center"
                    prop="projectAllCount"
                    label="项目数量（个）"
                    sortable="custom"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="projectAllPrice"
                    label="中标金额（亿元）"
                    sortable="custom"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="second-item">
            <div class="second-name second-type" id="type">
              <div>类型</div>
              <div class="second-name-line"></div>
            </div>
            <div class="third-box">
              <template v-if="!typeShowType">
                <div
                  class="third-type-name"
                  v-for="(item, i) in typeList.slice(0, 5)"
                  :key="i"
                >
                  <el-tooltip
                    effect="light"
                    :content="item.projectType"
                    placement="bottom"
                  >
                    <div class="name-box">
                      {{ item.projectType }}
                    </div>
                  </el-tooltip>
                  <div class="third-name-line third-name-line-end"></div>
                </div>
              </template>
              <template v-if="typeShowType">
                <div class="third-type-name" v-for="(item, i) in typeList" :key="i">
                  <el-tooltip
                    effect="light"
                    :content="item.projectType"
                    placement="bottom"
                  >
                    <div class="name-box">
                      {{ item.projectType }}
                    </div>
                  </el-tooltip>
                  <div class="third-name-line"></div>
                </div>
              </template>
              <div
                class="third-type-name"
                v-if="!typeShowType && typeList.length > 5"
                @click="opencloseType(1)"
                style="cursor: pointer"
              >
                展开（{{ typeList.length - 5 }}）
                <span
                  class="iconfont icon-zengjia"
                  style="font-size: 18px; margin-left: 20px; font-weight: bold"
                ></span>
              </div>
              <div
                class="third-type-name"
                v-if="typeShowType"
                @click="opencloseType(2)"
                style="cursor: pointer"
              >
                收起
                <span
                  class="iconfont icon-jianshao"
                  style="font-size: 18px; margin-left: 20px; font-weight: bold"
                ></span>
              </div>
              <div class="third-line" v-show="typeList.length != 1"></div>
              <div class="third-line-one" v-show="typeList.length == 1"></div>
            </div>
            <div
              class="winning-table winning-table-type type-table"
              :class="typeList.length < 6 ? 'winning-table-close' : ''"
            >
              <el-table
                v-if="!typeShowType"
                :data="typeList.slice(0, 5)"
                header-cell-class-name="table_header"
                style="width: 100%"
                @sort-change="sortTypeChange"
              >
                <el-table-column
                  align="center"
                  prop="projectAllCount"
                  label="项目数量（个）"
                  sortable="custom"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="projectAllPrice"
                  label="中标金额（亿元）"
                  sortable="custom"
                >
                </el-table-column>
              </el-table>
              <el-table
                v-if="typeShowType"
                :data="typeList"
                header-cell-class-name="table_header"
                style="width: 100%"
                @sort-change="sortTypeChange"
              >
                <el-table-column
                  align="center"
                  prop="projectAllCount"
                  label="项目数量（个）"
                  sortable="custom"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="projectAllPrice"
                  label="中标金额（亿元）"
                  sortable="custom"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty";
export default {
  components: {
    Empty,
  },
  data() {
    return {
      params: {},
      isData: false, // 是否查询到数据
      tenderee: "", // 招标人
      scaleNum: 1, // 放大缩小基数
      tendereeTop: "", // 招标人距离顶部距离
      secondLinTop: "", // 招标人连接线距离顶部距离
      secondLinHeight: "", // 招标人连接线高度
      winningShowType: false, // 中标人展开状态
      winningList: [], // 中标人数据
      winningSortList: [], // 中标人数据初始排序
      provinceShowType: false, // 省级展开状态
      provinceIndex: null, // 展开的市级数据对应省级的index
      areaList: [], // 省市区数据
      areaSortList: [], // 省市区数据初始排序
      cityList: [], // 市级数据
      citySortList: [], // 市级数据初始排序
      cityIndex: null, // 展开的区县数据对应市级的index
      countyList: [], // 区县数据
      countySortList: [], // 区县数据初始排序
      typeShowType: false, // 类型展开状态
      typeList: [], // 类型数据
      typeSortList: [], // 类型数据初始排序
      allNum: "",
      allPrice: "",
    };
  },
  //自定义指令
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let targetDiv = el; // 获取当前元素
        let firstTime = null;
        let lastTime = null;
        targetDiv.onmousedown = (e) => {
          firstTime = new Date().getTime();
          // console.log("onmousedown");
          // 算出鼠标相对元素的位置
          let reslutX = e.clientX - targetDiv.offsetLeft;
          let reslutY = e.clientY - targetDiv.offsetTop;

          document.onmousemove = (e) => {
            lastTime = new Date().getTime();
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - reslutX;
            let top = e.clientY - reslutY;

            targetDiv.style.left = left + "px";
            targetDiv.style.top = top + "px";
          };

          document.onmouseup = () => {
            document.onmousemove = null;
            document.onmouseup = null;
            if (lastTime - firstTime > 200) {
              // 拖拽
              document.getElementById("tenderee").setAttribute("data-flag", true);
            } else {
              // 点击
              document.getElementById("tenderee").setAttribute("data-flag", false);
            }
          };
        };
      },
    },
  },
  mounted() {},
  methods: {
    // 初始化搜索数据
    getParams(params) {
      this.params = params;
      this.getData(params);
    },
    // 获取数据
    getData(params) {
      this.tenderee = "";
      this.winningList = [];
      this.winningSortList = [];
      this.areaList = [];
      this.areaSortList = [];
      this.typeList = [];
      this.typeSortList = [];
      this.cityList = [];
      this.citySortList = [];
      this.countyList = [];
      this.countySortList = [];
      this.isData = false;
      this.provinceIndex = null;
      this.cityIndex = null;
      this.winningShowType = false;
      this.provinceShowType = false;
      this.typeShowType = false;
      const loading = this.$loading({
        lock: true,
        text: "搜索中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: false,
      });
      this.$api.industry
        .getClientInfoHistoryPro(params)
        .then((res) => {
          let data = res.data;
          if (
            data.projectByWinner.length != 0 &&
            data.projectByArea.length != 0 &&
            data.projectByProType.length != 0
          ) {
            this.tenderee = params.tenderee;
            this.winningList = data.projectByWinner;
            // 深拷贝
            this.winningSortList = [
              ...data.projectByWinner.map((item) => {
                return Object.assign({}, item);
              }),
            ];
            let allNum = 0;
            let allPrice = 0;
            data.projectByWinner.forEach((item) => {
              allNum = allNum + +item.projectAllCount;
              allPrice = allPrice + +item.projectAllPrice;
            });
            this.allNum = allNum;
            this.allPrice = allPrice.toFixed(4);
            let areaList = data.projectByArea;
            areaList.forEach((item) => {
              item.open = false;
            });
            this.areaList = areaList;
            // 深拷贝
            this.areaSortList = [
              ...areaList.map((item) => {
                return Object.assign({}, item);
              }),
            ];
            this.typeList = data.projectByProType;
            // 深拷贝
            this.typeSortList = [
              ...data.projectByProType.map((item) => {
                return Object.assign({}, item);
              }),
            ];
            this.isData = true;
            this.$nextTick(() => {
              this.getPosition();
            });
          } else {
            this.tenderee = "";
            this.winningList = [];
            this.winningSortList = [];
            this.areaList = [];
            this.areaSortList = [];
            this.typeList = [];
            this.typeSortList = [];
            this.cityList = [];
            this.citySortList = [];
            this.countyList = [];
            this.countySortList = [];
            this.isData = false;
          }
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    // 点击放大
    toMagnify() {
      let { scaleNum } = this;
      scaleNum += 0.1;
      scaleNum = +scaleNum.toFixed(1);
      if (scaleNum == 1.1) {
        return;
      }
      this.scaleNum = scaleNum;
      document.querySelector(".first-box").style.transform = `scale(${scaleNum})`;
    },
    // 点击缩小
    toShrink() {
      let { scaleNum } = this;
      scaleNum -= 0.1;
      scaleNum = +scaleNum.toFixed(1);
      if (scaleNum == 0.5) {
        return;
      }
      this.scaleNum = scaleNum;
      document.querySelector(".first-box").style.transform = `scale(${scaleNum})`;
    },
    // 点击刷新
    toRefresh() {
      this.scaleNum = 1;
      document.querySelector(".first-box").style.transform = "scale(1)";
      document.querySelector(".first-box").style.left = "0px";
      document.querySelector(".first-box").style.top = "0px";
    },
    // 计算一级招标人及连接线所在位置
    getPosition() {
      this.tendereeTop = this.getTop("area");
      this.secondLinTop = this.getTop("winning");
      this.secondLinHeight = this.getHeight("winning", "type");
    },
    // 获取距离父级盒子顶部距离
    getTop(id) {
      let top = document.getElementById(id).offsetTop + 20;
      return top;
    },
    // 获取两个盒子之间的高度距离
    getHeight(idone, idtwo) {
      let heightone = document.getElementById(idone).offsetTop;
      let heighttwo = document.getElementById(idtwo).offsetTop;
      let height = heighttwo - heightone;
      return height;
    },
    // 展开收起中标单位
    opencloseWinning(type) {
      let isClick = document.getElementById("tenderee").getAttribute("data-flag");
      if (isClick == "false") {
        if (type == 1) {
          this.winningShowType = true;
          this.$nextTick(() => {
            this.getPosition();
          });
        } else {
          this.winningShowType = false;
          this.$nextTick(() => {
            this.getPosition();
          });
        }
      }
    },
    // 展开收起省级
    opencloseProvince(type) {
      let isClick = document.getElementById("tenderee").getAttribute("data-flag");
      if (isClick == "false") {
        // 清空市级及区县数据
        this.clearCityCountyData();
        if (type == 1) {
          this.provinceShowType = true;
          this.$nextTick(() => {
            this.getPosition();
          });
        } else {
          this.provinceShowType = false;
          this.$nextTick(() => {
            this.getPosition();
          });
        }
      }
    },
    // 点击省级数据展开收起市级
    openCloseCity(type, item, index) {
      let isClick = document.getElementById("tenderee").getAttribute("data-flag");
      if (isClick == "false") {
        let { areaList, cityList, params } = this;
        if (type == 1) {
          // 展开对应市
          this.provinceIndex = index;
          areaList.forEach((item) => {
            item.open = false;
          });
          areaList[index].open = true;
          // 获取市级数据
          params.provinceArea = item.province;
          this.$api.industry
            .getClientInfoHistoryProCity(params)
            .then((res) => {
              this.areaList = areaList;
              cityList.forEach((item) => {
                item.open = false;
              });
              this.cityList = cityList;
              res.data.forEach((item) => {
                item.open = false;
              });
              this.cityList = res.data;
              this.citySortList = [
                ...res.data.map((item) => {
                  return Object.assign({}, item);
                }),
              ];
              this.cityIndex = null;
              this.countyList = [];
              this.$nextTick(() => {
                this.getPosition();
              });
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        } else {
          areaList[index].open = false;
          // 清空市级及区县数据
          this.clearCityCountyData();
          this.$nextTick(() => {
            this.getPosition();
          });
        }
      }
    },
    // 点击市级数据展开收起区县级
    openCloseCounty(type, item, index) {
      let isClick = document.getElementById("tenderee").getAttribute("data-flag");
      if (isClick == "false") {
        let { cityList, params } = this;
        if (type == 1) {
          // 展开对应区县
          this.cityIndex = index;
          cityList.forEach((item) => {
            item.open = false;
          });
          cityList[index].open = true;
          // 获取区级数据
          params.cityArea = item.city;
          this.$api.industry
            .getClientInfoHistoryProCounty(params)
            .then((res) => {
              this.cityList = cityList;
              this.countyList = res.data;
              this.countySortList = [
                ...res.data.map((item) => {
                  return Object.assign({}, item);
                }),
              ];
              this.$nextTick(() => {
                this.getPosition();
              });
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        } else {
          cityList[index].open = false;
          this.cityIndex = null;
          this.countyList = [];
          this.$nextTick(() => {
            this.getPosition();
          });
        }
      }
    },
    // 展开收起类型
    opencloseType(type) {
      let isClick = document.getElementById("tenderee").getAttribute("data-flag");
      if (isClick == "false") {
        if (type == 1) {
          this.typeShowType = true;
          this.$nextTick(() => {
            this.getPosition();
          });
        } else {
          this.typeShowType = false;
          this.$nextTick(() => {
            this.getPosition();
          });
        }
      }
    },
    // 计算市级父级盒子的高度
    getCityHeight() {
      // 获取省级数据个数
      let areaLength = this.areaList.length;
      let cityLength = this.cityList.length;
      if (areaLength > 5) {
        if (this.provinceShowType) {
          // 展开时
          // 省级高度加市级高度 + 一个展开的高度  - 1个高度
          return (areaLength + cityLength - 2) * 47 + (areaLength + cityLength - 3) * 20;
        } else {
          // 收起时 固定高度
          return (6 + cityLength - 1) * 47 + (6 + cityLength - 2) * 20;
        }
      } else {
        // 省级高度加市级高度 - 1个高度
        return (areaLength + cityLength - 1) * 47 + (areaLength + cityLength - 2) * 20;
      }
    },
    // 计算市级数据距离父级盒子的顶部距离
    getCityTop() {
      if (this.provinceShowType) {
        return (47 + 20) * (this.provinceIndex - 1);
      } else {
        return (47 + 20) * this.provinceIndex;
      }
    },
    // 计算区县父级盒子的高度
    getCountyHeight() {
      // 获取省级数据个数
      let areaLength = this.areaList.length;
      let cityLength = this.cityList.length;
      let countyLength = this.countyList.length;
      if (areaLength > 5) {
        if (this.provinceShowType) {
          // 展开时
          // 省级高度加市级高度 + 一个展开的高度  - 1个高度
          return (
            (areaLength + cityLength + countyLength - 2) * 47 +
            (areaLength + cityLength + countyLength - 3) * 20
          );
        } else {
          // 收起时 固定高度
          return (
            (6 + cityLength + countyLength - 1) * 47 +
            (6 + cityLength + countyLength - 2) * 20
          );
        }
      } else {
        // 省级高度加市级高度 - 1个高度
        return (
          (areaLength + cityLength + countyLength - 1) * 47 +
          (areaLength + cityLength + countyLength - 2) * 20
        );
      }
    },
    // 计算区县数据距离父级盒子的顶部距离
    getCountyTop() {
      if (this.provinceShowType) {
        return (47 + 20) * (this.provinceIndex + this.cityIndex - 1) + 34;
      } else {
        return (47 + 20) * (this.provinceIndex + this.cityIndex) + 34;
      }
    },
    // 清空市级及区县数据
    clearCityCountyData() {
      let { areaList, cityList } = this;
      // 清空市级数据
      areaList.forEach((item) => {
        item.open = false;
      });
      cityList.forEach((item) => {
        item.open = false;
      });
      this.areaList = areaList;
      this.provinceIndex = null;
      this.cityList = [];
      // 清空区县数据
      this.cityIndex = null;
      this.countyList = [];
    },
    // 中标单位表格排序
    sortWinningChange({ prop, order }) {
      let initData = [
        ...this.winningSortList.map((item) => {
          return Object.assign({}, item);
        }),
      ];
      if (!this.winningShowType && this.winningList.length > 5) {
        // 超过5条数据默认展开数据后进行排序
        this.winningShowType = true;
        this.$nextTick(() => {
          this.getPosition();
        });
      }
      if (order === "descending") {
        // 降序
        this.winningList = this.winningList.sort((a, b) =>
          this.getForSort(b[prop], a[prop])
        );
      } else if (order === "ascending") {
        // 升序
        this.winningList = this.winningList.sort((a, b) =>
          this.getForSort(a[prop], b[prop])
        );
      } else {
        // 默认排序
        this.winningList = initData;
      }
    },
    // 省份数据表格排序
    sortProvinceChange({ prop, order }) {
      let initData = [
        ...this.areaSortList.map((item) => {
          return Object.assign({}, item);
        }),
      ];
      if (!this.provinceShowType && this.areaList.length > 5) {
        // 超过5条数据默认展开数据后进行排序
        this.provinceShowType = true;
        this.$nextTick(() => {
          this.getPosition();
        });
      }
      if (order === "descending") {
        // 降序
        this.areaList = this.areaList.sort((a, b) => this.getForSort(b[prop], a[prop]));
      } else if (order === "ascending") {
        // 升序
        this.areaList = this.areaList.sort((a, b) => this.getForSort(a[prop], b[prop]));
      } else {
        // 默认排序
        this.areaList = initData;
      }
    },
    // 市级数据表格排序
    sortCityChange({ prop, order }) {
      let initData = [
        ...this.citySortList.map((item) => {
          return Object.assign({}, item);
        }),
      ];
      if (order === "descending") {
        // 降序
        this.cityList = this.cityList.sort((a, b) => this.getForSort(b[prop], a[prop]));
      } else if (order === "ascending") {
        // 升序
        this.cityList = this.cityList.sort((a, b) => this.getForSort(a[prop], b[prop]));
      } else {
        // 默认排序
        this.cityList = initData;
      }
    },
    // 区县数据表格排序
    sortCountyChange({ prop, order }) {
      let initData = [
        ...this.countySortList.map((item) => {
          return Object.assign({}, item);
        }),
      ];
      if (order === "descending") {
        // 降序
        this.countyList = this.countyList.sort((a, b) =>
          this.getForSort(b[prop], a[prop])
        );
      } else if (order === "ascending") {
        // 升序
        this.countyList = this.countyList.sort((a, b) =>
          this.getForSort(a[prop], b[prop])
        );
      } else {
        // 默认排序
        this.countyList = initData;
      }
    },
    // 类型数据表格排序
    sortTypeChange({ prop, order }) {
      let initData = [
        ...this.typeSortList.map((item) => {
          return Object.assign({}, item);
        }),
      ];
      if (!this.typeShowType && this.typeList.length > 5) {
        // 超过5条数据默认展开数据后进行排序
        this.typeShowType = true;
        this.$nextTick(() => {
          this.getPosition();
        });
      }
      if (order === "descending") {
        // 降序
        this.typeList = this.typeList.sort((a, b) => this.getForSort(b[prop], a[prop]));
      } else if (order === "ascending") {
        // 升序
        this.typeList = this.typeList.sort((a, b) => this.getForSort(a[prop], b[prop]));
      } else {
        // 默认排序
        this.typeList = initData;
      }
    },
    // 排序方法
    getForSort(stra, strb) {
      if (typeof stra === typeof strb && typeof strb === "string") {
        //汉字排序
        return stra.localeCompare(strb);
      } else {
        //数字排序
        return stra - strb;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
