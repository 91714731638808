<template>
  <div class="customer-main">
    <div class="customer-title-box">
      <div>人员信息</div>
      <div class="title-right" @click="handleAddCustomer">
        <img
          src="@/assets/img/user/addmember.png"
          width="100%"
          height="100%"
          @dragstart.prevent
          class="img-setting"
        />
        <span class="customer">新增主要成员</span>
      </div>
    </div>
    <!-- <div class="divider"></div> -->
    <div class="customer-content">
      <div v-if="total == 0" style="margin-top: 150px">
        <empty name="暂无数据"></empty>
      </div>
      <div class="customer-box" v-show="total != 0">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table_header"
          height="645px"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="staffName"
            label="姓名"
            width="80"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column prop="staffSex" label="性别" width="50" align="center">
            <template slot-scope="scope">{{
              scope.row.staffSex == "300201"
                ? "男"
                : scope.row.staffSex == "300202"
                ? "女"
                : scope.row.staffSex == "300203"
                ? "未知"
                : "--"
            }}</template>
          </el-table-column>
          <el-table-column
            prop="staffPosition"
            label="职务"
            width="140"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="staffContact"
            label="联系方式"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="socialRelations"
            label="社会关系"
            width="160"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{
              scope.row.socialRelations || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            prop="photoId"
            label="照片"
            width="100"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              <span class="view-style" @click="handlePhoto(scope.row.photoId)">
                <!-- {{ scope.row.photoId }} -->
                查看
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="introduction"
            label="个人简介"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span class="view-style" @click="handleView(scope.row.introduction)"
                >详情</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="remarks"
            label="备注"
            width="120"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.remarks || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="createByName"
            label="首次添加人"
            width="100"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.createByName || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="createDate"
            label="首次添加时间"
            width="180"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.createDate || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="updateByName"
            label="最后更新人"
            width="100"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.updateByName || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="updateDate"
            label="最后更新时间"
            width="180"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.updateDate || "-" }}</template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="120" align="center">
            <template slot-scope="scope">
              <span
                class="view-style"
                style="margin-right: 20px"
                @click="editMember(scope.row)"
                >修改</span
              >
              <span class="view-style" @click="delMember(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        >
        </pagination>
      </div>
    </div>
    <!-- 新增主要成员   dialog -->
    <Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        {{ customerTitle }}
      </div>
      <el-form
        :model="dataPO"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="姓名" prop="staffName" class="title-item">
              <el-input
                v-model.trim="dataPO.staffName"
                placeholder="请输入姓名"
                maxlength="10"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="性别" prop="staffSex" class="title-item">
              <el-radio-group v-model="dataPO.staffSex">
                <el-radio
                  v-for="(item, index) in genderList"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="职务" prop="staffPosition" class="title-item">
              <el-input
                v-model.trim="dataPO.staffPosition"
                placeholder="请输入职务"
                maxlength="20"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系方式" prop="staffContact" class="title-item">
              <el-input
                v-model.trim="dataPO.staffContact"
                placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="社会关系" prop="socialRelations" class="title-item">
              <el-input
                v-model.trim="dataPO.socialRelations"
                placeholder="请输入社会关系"
                maxlength="200"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="照片" prop="photoId" class="upload-item">
              <el-upload
                :class="{ uploadSty: showBtnDealImg, disUploadSty: noneBtnImg }"
                ref="uploadPic"
                :action="uploadImgUrl"
                :headers="headerObj"
                list-type="picture-card"
                :auto-upload="true"
                :on-change="handleChangePic"
                :on-success="handlePicSuccess"
                :before-upload="beforePicUpload"
                :file-list="fileList"
                :limit="1"
                accept=".jpg, .jpeg, .png"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!imgDisabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
                <div slot="tip" class="el-upload__tip">
                  只能上传一张jpg/jpeg/png文件，且不能超过5M
                </div>
              </el-upload>
              <el-dialog
                width="37%"
                :visible.sync="imgDialogVisible"
                :append-to-body="true"
              >
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="个人简介" prop="introduction" class="title-item">
              <el-input
                v-model.trim="dataPO.introduction"
                placeholder="请输入个人简介"
                maxlength="200"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks" class="title-item">
              <el-input
                type="textarea"
                v-model.trim="dataPO.remarks"
                placeholder="请输入备注信息，限1000字"
                :rows="8"
                maxlength="1000"
                resize="none"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item class="btn-item">
              <el-button @click="cancel()">取消</el-button>
              <el-button type="primary" @click="submitForm('form')">确认</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Modal>
    <!-- 个人简介详情 -->
    <Modal
      :visible.sync="detailVisible"
      @reset="resetDetailFields"
      class="dialog-detail-style"
    >
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        {{ detailTitle }}
      </div>
      <el-form
        :model="detailData"
        ref="detailForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="24">
            <div class="content">
              {{ content }}
            </div>
          </el-col>
          <!-- <el-col>
            <el-form-item class="btn-item">
              <el-button @click="cancelDetail()">取消</el-button>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </Modal>
    <!-- 照片弹窗 -->
    <Modal
      :visible.sync="photoVisible"
      @reset="resetPhotoFields"
      class="dialog-photo-style"
    >
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        {{ photoTitle }}
      </div>
      <el-form
        :model="photoData"
        ref="photoForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="24" class="col">
            <div class="content">
              <img
                :src="imgUrl"
                width="100%"
                height="100%"
                @dragstart.prevent
                class="img-setting"
              />
            </div>
          </el-col>
          <!-- <el-col>
            <el-form-item class="btn-item">
              <el-button @click="cancelPhoto()">取消</el-button>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </Modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import { checkPhone } from "@/utils/validate.js";
export default {
  components: {
    Pagination,
    Empty,
    Modal,
  },
  data() {
    return {
      total: 0,
      tableData: [],
      genderList: [
        { label: "男", value: "300201" },
        { label: "女", value: "300202" },
        { label: "未知", value: "300203" },
      ],
      visible: false,
      customerTitle: "新增主要成员",
      showBtnDealImg: true,
      noneBtnImg: false,
      uploadImgUrl: (process.env.VUE_APP_BASE_URL||'') + "/mds/api/file/upload", // 上传图片服务器地址
      headerObj: {
        Authorization: this.$session.getLoginToken(),
      },
      imgDisabled: false,
      imgDialogVisible: false,
      dialogImageUrl: "",
      fileList: [], // 上传的logo
      imgUrl: "",
      content: "",
      dataPO: {},
      rules: {
        staffName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        staffSex: [{ required: true, message: "请选择性别", trigger: "change" }],
        staffPosition: [{ required: true, message: "请输入职务", trigger: "blur" }],
        staffContact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
      },
      submitLoading: false,

      detailVisible: false,
      detailTitle: "个人简介",
      detailData: {},
      photoData: {},

      photoVisible: false,
      photoTitle: "查看照片",
      params: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    // this.getClientStaff();
  },
  methods: {
    // 获取列表
    getClientStaff() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true,
      });
      this.$api.personalCenter
        .getClientStaff({
          clientId: this.$route.query.id,
          ...this.params,
        })
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
          loading.close();
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
          loading.close();
        });
    },
    // 点击进入个人简介
    handleView(val) {
      this.content = val;
      this.detailVisible = true;
    },
    // 点击进入照片
    handlePhoto(val) {
      if (val) {
        this.$api.personalCenter
          .getFileInfo({ fileId: val })
          .then((res) => {
            let url =
              process.env.VUE_APP_FILEURL + res.data.fileDir + "/" + res.data.fileKey;
            this.imgUrl = url;
          })
          .catch((msg) => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      }
      this.photoVisible = true;
    },
    // 删除方法
    delMember(row) {
      this.$confirm("确认删除该成员吗？", "提示", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.personalCenter
            .deleteClientStaff({
              consumerClientStaffId: row.consumerClientStaffId,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getClientStaff();
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        })
        .catch(() => {});
    },
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.getClientStaff();
    },
    // 新建成员按钮
    handleAddCustomer() {
      this.visible = true;
      this.customerTitle = "新增主要成员";
      // this.resetFields();
      this.dataPO = {};
      this.fileList = [];
      this.noneBtnImg = false;
    },
    // 上传封面图片方法
    handleChangePic(file, fileList) {},
    handlePicSuccess(res, file, filer) {
      if (res.code == 200) {
        this.noneBtnImg = true;
        this.$message.success("上传成功");
        this.dataPO.photoId = res.data.fileId;
        this.$refs.form.clearValidate("photoId");
      } else {
        this.$refs.uploadPic.handleRemove(file);
        this.$message.error("上传失败");
      }
    },
    beforePicUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传封面图片大小不能超过5M!");
      }
      return isLt5M;
    },
    handleRemove(file, fileList) {
      this.$refs.uploadPic.handleRemove(file);
      this.dataPO.photoId = "";
      this.noneBtnImg = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialogVisible = true;
    },
    // 修改按钮
    editMember(row) {
      this.visible = true;
      this.customerTitle = "修改主要成员";
      this.dataPO = JSON.parse(JSON.stringify(row));
      if (row.photoId) {
        this.$api.personalCenter
          .getFileInfo({ fileId: row.photoId })
          .then((res) => {
            let url =
              process.env.VUE_APP_FILEURL + res.data.fileDir + "/" + res.data.fileKey;
            this.noneBtnImg = true;
            this.fileList.push({ url: url });
          })
          .catch((msg) => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      }
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.form.resetFields();
      this.fileList = [];
      this.noneBtnImg = false;
      this.dataPO = {};
    },
    resetDetailFields() {
      this.$refs.detailForm.resetFields();
      this.detailData = {};
    },
    resetPhotoFields() {
      this.$refs.photoForm.resetFields();
      this.photoData = {};
    },
    // 表单提交事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dataPO.consumerClientStaffId) {
            this.$api.personalCenter
              .updateClientStaff({
                ...this.dataPO,
                clientId: this.$route.query.id,
              })
              .then((res) => {
                this.visible = false;
                this.$message.success("修改成功");
                this.fileList = [];
                this.noneBtnImg = false;
                this.dataPO = {};
                this.getClientStaff();
              })
              .catch((msg) => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          } else {
            this.$api.personalCenter
              .insertClientStaff({
                ...this.dataPO,
                clientId: this.$route.query.id,
              })
              .then((res) => {
                this.visible = false;
                this.$message.success("新增成功");
                this.fileList = [];
                this.noneBtnImg = false;
                this.dataPO = {};
                this.getClientStaff();
              })
              .catch((msg) => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          }
        } else {
          this.$message.error("提交失败!");
          return false;
        }
      });
    },
    // 表单取消
    cancel() {
      this.visible = false;
      this.resetFields();
    },
    //
    cancelDetail() {
      this.detailVisible = false;
    },
    cancelPhoto() {
      this.photoVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
