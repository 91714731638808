<template>
  <div class="notepad-modal">
    <Modal :visible.sync="visible">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        记事本
      </div>
      <div class="notepad-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="记事本" name="navOne">
            <div class="notepad-textarea">
              <el-input
                type="textarea"
                v-model="content"
                maxlength="500"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我的记录" name="navTwo">
            <div class="my-record">
              <div v-show="list.length == 0" style="width: 100%; margin: 50px 0 0 10px">
                <empty name="暂无记录"></empty>
              </div>
              <div v-for="item in list" :key="item.consumerClientNotepadId">
                <div class="record-textarea" v-show="item.isEdit">
                  <el-input
                    :id="'input' + item.consumerClientNotepadId"
                    type="textarea"
                    v-model="item.info"
                    maxlength="500"
                    placeholder="请输入内容"
                    @blur="infoBlur(item)"
                  ></el-input>
                </div>
                <div class="record-box" v-show="!item.isEdit">
                  <div class="record-main">{{ item.info }}</div>
                  <div class="record-bottom">
                    <div class="record-time">{{ item.updateDate }}</div>
                    <div class="record-btn">
                      <div
                        :style="{
                          color: editType ? '#C1C1C1' : '',
                          cursor: editType ? 'not-allowed' : 'pointer',
                        }"
                        class="record-edit"
                        @click="toEdit(item)"
                      >
                        编辑
                      </div>
                      <div
                        :style="{
                          color: editType ? '#C1C1C1' : '',
                          cursor: editType ? 'not-allowed' : 'pointer',
                        }"
                        @click="toDelete(item)"
                      >
                        删除
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="btn-box" v-show="activeName == 'navOne'">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submitForm"
          >确认</el-button
        >
      </div>
      <div style="height: 40px" v-show="activeName == 'navTwo'"></div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Empty from "@/components/Empty";
export default {
  components: {
    Modal,
    Empty,
  },
  data() {
    return {
      visible: false,
      activeName: "navOne",
      content: "",
      list: [],
      id: "",
      editInfo: "",
      editType: false,
      submitLoading: false,
    };
  },
  methods: {
    // 编辑
    toEdit(item) {
      if (this.editType) {
        return;
      }
      this.editType = true;
      this.editInfo = item.info;
      let id = item.consumerClientNotepadId;
      let { list } = this;
      list.forEach((item) => {
        item.isEdit = false;
        if (item.consumerClientNotepadId == id) {
          item.isEdit = true;
        }
      });

      this.list = list;
      this.activeName = "navOne";
      this.activeName = "navTwo";
      this.$nextTick(() => {
        document.getElementById("input" + item.consumerClientNotepadId).focus();
      });
    },
    // 失去焦点时
    infoBlur(item) {
      if (item.info == "") {
        this.$message.error("内容不能为空");
        return;
      }
      if (this.editInfo == item.info) {
        this.editType = false;
        let { list } = this;
        list.forEach((item) => {
          item.isEdit = false;
        });

        this.list = list;
        this.activeName = "navOne";
        this.activeName = "navTwo";
        return;
      }
      this.$api.industry
        .updateClientInfoNotePad({
          clientId: this.id,
          consumerClientNotepadId: item.consumerClientNotepadId,
          info: item.info,
        })
        .then(() => {
          this.$api.industry
            .getClientInfoNotePad({
              clientId: this.id,
            })
            .then((res) => {
              this.$message.success("修改成功");
              this.list = res.data;
              this.editType = false;
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 删除
    toDelete(item) {
      if (this.editType) {
        return;
      }
      let id = item.consumerClientNotepadId;
      this.$api.industry
        .deleteClientInfoNotePad({
          consumerClientNotepadId: id,
        })
        .then(() => {
          this.$message.success("删除成功");
          let { list } = this;
          let endList = [];
          list.forEach((item) => {
            if (item.consumerClientNotepadId != id) {
              endList.push(item);
            }
          });
          this.list = endList;
          this.$parent.mdsClientInfo.noteCount -= 1;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 取消
    cancel() {
      this.visible = false;
    },
    // 确定
    submitForm() {
      let { content } = this;
      if (content == "") {
        this.$message.error("内容不能为空");
        return;
      }
      this.submitLoading = true;
      this.$api.industry
        .addClientInfoNotePad({
          clientId: this.id,
          info: content,
        })
        .then(() => {
          this.$api.industry
            .getClientInfoNotePad({
              clientId: this.id,
            })
            .then((res) => {
              this.$message.success("添加成功");
              this.activeName = "navTwo";
              this.list = res.data;
              this.content = "";
              this.$parent.mdsClientInfo.noteCount += 1;
              this.submitLoading = false;
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
