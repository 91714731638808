<template>
	<div class="customer">
		<div class="breadcrumb">
			<el-breadcrumb separator="/">
				<!-- <el-breadcrumb-item :to="{ path: '/user/my-collect' }"
          >我的收藏</el-breadcrumb-item
        > -->
				<el-breadcrumb-item :to="{path: '/user/my-collect/customer'}">我的客户</el-breadcrumb-item>
				<el-breadcrumb-item>我的客户详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="customer-container">
			<!-- <div class="customer-title-box">
        <div>我的客户</div>
      </div>
      <div class="divider"></div> -->
			<div class="customer-info">
				<div class="left-info">
					<img class="item-icon" v-if="detail.logoUrl" @dragstart.prevent :src="detail.logoUrl" alt="" />
					<div class="img-left-logo" v-else style="background-color: #6daae2">
						<div class="logo-img">{{ detail.logoImg }}</div>
					</div>
				</div>
				<div class="right-info">
					<div class="info-top">
						<el-tooltip effect="light" :content="detail.clientName" placement="bottom-start">
							<div class="top-left">{{ detail.clientName }}</div>
						</el-tooltip>
						<div class="top-right">
							<div class="title-right" v-show="detail.isJoin == 1" @click="joinMyClient(0)">
								<img src="@/assets/img/user/yichu.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
								<span class="customer">移除客户列表</span>
							</div>
							<div class="title-right" v-show="detail.isJoin == 0" @click="joinMyClient(1)">
								<img src="@/assets/img/user/add.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
								<span class="customer-none">加入客户列表</span>
							</div>
							<div class="title-right" v-show="detail.isJsubscribe == 1" @click="jsubscribeClient(0)">
								<img src="@/assets/img/user/quxiaodingyue.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
								<span class="customer">取消订阅</span>
							</div>
							<div class="title-right" v-show="detail.isJsubscribe == 0" @click="jsubscribeClient(1)">
								<img src="@/assets/img/user/dingyue.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
								<span class="customer-none">加入订阅</span>
							</div>
							<div class="title-right" @click="toNotepad">
								<img src="@/assets/img/user/xiugai.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
								<span class="customer margin">记事本 | {{ mdsClientInfo.noteCount }}</span>
							</div>
						</div>
						<!-- <div class="bianjiao">
              <img
                src="@/assets/img/user/code.png"
                width="100%"
                height="100%"
                @dragstart.prevent
                class="img-setting"
              />
              <img
                class="erweima animated fadeIn"
                src="@/assets/img/user/erweima.png"
                width="100%"
                height="100%"
                @dragstart.prevent
              />
            </div> -->
					</div>
					<div class="info-bottom">
						<div class="info-item">
							<span class="info-title">
								企业法人：
								<span>{{ detail.legalPerson || "-" }}</span>
							</span>
							<span class="info-content">
								注册资本：
								<span>{{ detail.registeredCapital || "-" }}</span>
							</span>
						</div>
						<div class="info-item">
							<span class="info-title">
								成立日期：
								<span>{{ detail.establishDate || "-" }}</span>
							</span>
							<span class="info-content">
								联系电话：
								<span>{{ detail.phone || "-" }}</span>
							</span>
						</div>

						<div class="info-item">
							<span class="info-title">微信公众号：{{ detail.wechat || "-" }}</span>
							<span class="info-content">
								企业官网：
								<span class="web" @click="goWeb(detail.websiteLink)">{{ detail.websiteLink || "-" }}</span>
							</span>
						</div>
						<div class="info-item">
							<span class="info-title" style="width: 700px">企业地址：{{ detail.address || "-" }}</span>
						</div>
						<div class="info-items">
							<span>添加人：{{ detail.createByName || "-" }}</span>
							<span class="title">添加时间：{{ detail.createDate || "-" }}</span>
							<span class="title" style="margin-left: 30px">最后更新人：{{ detail.updateByName || "-" }}</span>
							<span class="title">最后更新时间：{{ detail.updateDte || "-" }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- tab页面详情 -->
		<div class="main-msg-box">
			<div class="nav-box" :class="activeName == 'navFour' ? 'nav-box-four' : ''">
				<div :class="activeName == 'navFive' ? 'nav-box-five' : ''">
					<el-tabs v-model="activeName" :before-leave="beforeleaveNav">
						<el-tab-pane label="基本信息" name="navOne" v-if="type == 1"></el-tab-pane>
						<el-tab-pane label="人员信息" name="personInfo"></el-tab-pane>
						<el-tab-pane label="客户动态" name="navTwo"></el-tab-pane>
						<el-tab-pane label="招标动态" name="navThree"></el-tab-pane>
						<el-tab-pane label="历史项目数据" name="navFour"></el-tab-pane>
						<el-tab-pane label="项目跟踪记录" name="trackRecord"></el-tab-pane>
						<el-tab-pane label="客户资料库" name="navFive"></el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<div class="nav-main">
				<div v-show="activeName == 'navOne' && type == 1">
					<div class="main-title">企业简介</div>
					<div class="intro">{{ detail.introduction || "-" }}</div>
					<div class="main-title">工商信息</div>
					<div class="business-info">
						<div class="table-row">
							<div class="table-header">企业法人</div>
							<div class="table-mian">{{ clientBasic.legalPerson || "-" }}</div>
							<div class="table-header">经营状态</div>
							<div class="table-mian">
								{{ clientBasic.businessStatus || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">成立时间</div>
							<div class="table-mian">
								{{ clientBasic.establishDate || "-" }}
							</div>
							<div class="table-header">核准日期</div>
							<div class="table-mian">{{ clientBasic.examineDate || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">注册资本</div>
							<div class="table-mian">
								{{ clientBasic.registeredCapital || "-" }}
							</div>
							<div class="table-header">实缴资本</div>
							<div class="table-mian">{{ clientBasic.paidCapital || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">统一社会信用代码</div>
							<div class="table-mian">{{ clientBasic.creditCode || "-" }}</div>
							<div class="table-header">组织机构代码</div>
							<div class="table-mian">
								{{ clientBasic.organizationCode || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">工商注册号</div>
							<div class="table-mian">
								{{ clientBasic.registrationNumber || "-" }}
							</div>
							<div class="table-header">经营期限</div>
							<div class="table-mian">
								{{ clientBasic.businessPeriod || "-" }}
							</div>
						</div>
						<div class="table-row">
							<div class="table-header">所属行业</div>
							<div class="table-mian">
								{{ clientBasic.subordinateIndustry || "-" }}
							</div>
							<div class="table-header">企业类型</div>
							<div class="table-mian">{{ clientBasic.companyType || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">登记机关</div>
							<div class="table-mian">
								{{ clientBasic.registrationAuthority || "-" }}
							</div>
							<div class="table-header">所属地区</div>
							<div class="table-mian">{{ clientBasic.area || "-" }}</div>
						</div>
						<div class="table-row">
							<div class="table-header">企业注册地址</div>
							<div class="registered-ddress">
								{{ clientBasic.address || "-" }}
							</div>
						</div>
						<div class="table-row-end">
							<div class="table-header-end">经营范围</div>
							<div class="table-mian-end">
								{{ clientBasic.businessScope || "-" }}
							</div>
						</div>
					</div>
					<div class="main-title">股东信息</div>
					<div class="shareholder-table">
						<el-table header-cell-class-name="table_header" :data="shareholderList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="name" label="股东名称" align="center"></el-table-column>
							<el-table-column prop="totalRealCapi" label="总实缴" align="center"></el-table-column>
							<el-table-column prop="identifyNo" label="企业证照号" align="center"></el-table-column>
							<el-table-column prop="totalShouldCapi" label="总认缴" align="center"></el-table-column>
							<el-table-column prop="stockPercent" label="持股比例" align="center"></el-table-column>
							<el-table-column prop="identifyType" label="类型" align="center"></el-table-column>
							<el-table-column prop="stockType" label="股东类型" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="shareholderTotal" @change="shareholderPaginChange" v-show="shareholderTotal != 0"></pagination>
					</div>
					<div class="main-title">主要人员</div>
					<div class="shareholder-table">
						<el-table header-cell-class-name="table_header" :data="principalList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="principalName" label="姓名" align="center"></el-table-column>
							<el-table-column prop="principalPosition" label="职务" align="center"></el-table-column>
							<el-table-column prop="isHistory" label="是否为历史人员" align="center">
								<template slot-scope="scope">
									<span>{{ scope.row.isHistory == "10011001" ? "是" : "否" }}</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="principalTotal" @change="principalPaginChange" v-show="principalTotal != 0"></pagination>
					</div>
					<div class="main-title">对外投资</div>
					<div class="shareholder-table">
						<el-table header-cell-class-name="table_header" :data="investmentList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="enterprise" label="股东名称" align="center"></el-table-column>
							<el-table-column prop="shortName" label="简称" align="center"></el-table-column>
							<el-table-column prop="amount" label="投资金额(万元)" align="center"></el-table-column>
							<el-table-column prop="percent" label="持股比例" align="center"></el-table-column>
							<el-table-column prop="newStatus" label="经营状态" align="center">
								<template slot-scope="scope">
									<span>{{ getNewStatus(scope.row.newStatus) }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="shType" label="股东类型" align="center"></el-table-column>
							<el-table-column prop="investCreditNo" label="股东统一社会信用代码" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="investmentTotal" @change="investmentPaginChange" v-show="investmentTotal != 0"></pagination>
					</div>
					<div class="main-title">实际控制企业</div>
					<div class="shareholder-table">
						<el-table header-cell-class-name="table_header" :data="controlsList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="pname" label="投资方" align="center"></el-table-column>
							<el-table-column prop="ename" label="投资企业名称" align="center"></el-table-column>
							<el-table-column prop="percent" label="控股比例" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="controlsTotal" @change="controlsPaginChange" v-show="controlsTotal != 0"></pagination>
					</div>
					<div class="main-title">分支机构</div>
					<div class="shareholder-table">
						<el-table header-cell-class-name="table_header" :data="branchesList" border style="width: 100%">
							<el-table-column prop="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="name" label="分支机构名称" align="center"></el-table-column>
							<el-table-column prop="registCapi" label="注册资本" align="center"></el-table-column>
							<el-table-column prop="startDate" label="成立日期" align="center"></el-table-column>
							<el-table-column prop="belongOrg" label="登记机关" align="center"></el-table-column>
							<el-table-column prop="operName" label="企业法定代表人" align="center"></el-table-column>
							<el-table-column prop="status" label="企业状态" align="center"></el-table-column>
							<el-table-column prop="creditNo" label="统一社会信用代码" align="center"></el-table-column>
						</el-table>
					</div>
					<div style="margin-top: 20px">
						<pagination ref="pagination" :total="branchesTotal" @change="branchesPaginChange" v-show="branchesTotal != 0"></pagination>
					</div>
					<div class="main-title" v-show="mdsClientActualOwner != ''">疑似实控人</div>
					<div class="person-box" v-show="mdsClientActualOwner != ''">
						<RelationGraph ref="seeksRelationGraph" :options="graphOptions" />
					</div>
				</div>
				<div v-show="activeName == 'personInfo'">
					<person-info ref="personInfo"></person-info>
				</div>
				<div v-show="activeName == 'navTwo'">
					<div class="title-box">
						<div class="main-title">客户动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ dynamicTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="dynamicStart" type="month" :picker-options="dynamicStartTime" placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="dynamicEnd" type="month" :picker-options="dynamicEndTime" placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<el-button type="primary" @click="dynamicQuery">查询</el-button>
					</div>
					<div class="empty-box" v-show="dynamicTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-item" v-for="item in pageDynamic" :key="item.dynamicId" @click="toDynamicDetail(item.dynamicId)">
						<div class="name-left">
							<div class="tag-icon"></div>
							<el-tooltip effect="light" :content="item.title" placement="bottom-start">
								<div class="name-box text-ellipsis">
									{{ item.title }}
								</div>
							</el-tooltip>
						</div>
						<div class="date-right">
							<div class="serial-code">{{ item.websiteLocation }}</div>
							<div class="date">{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="dynamicTotal" @change="dynamicPaginChange" v-show="dynamicTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navThree'">
					<div class="title-box">
						<div class="main-title">招标动态</div>
						<div class="total-right">
							<span>共找到</span>
							<span class="total">{{ biddingTotal }}</span>
							<span>条结果</span>
						</div>
					</div>
					<div class="time-picker">
						<div class="custom-box">
							<div class="custom">时间</div>
							<div>
								<el-date-picker v-model="bidsStart" type="month" :picker-options="bidsStartTime" placeholder="开始时间" value-format="yyyy-MM"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="bidsEnd" type="month" :picker-options="bidsEndTime" placeholder="结束时间" value-format="yyyy-MM"></el-date-picker>
							</div>
						</div>
						<div class="search-right">
							<el-input placeholder="请输入招标公告标题" prefix-icon="el-icon-search" v-model="bidInput"></el-input>
							<el-button type="primary" @click="bidsQuery">查询</el-button>
						</div>
					</div>
					<div class="empty-box" v-show="biddingTotal == 0">
						<empty :name="'暂无数据'"></empty>
					</div>
					<div class="list-bidding" v-for="(item, index) in pageBidding" :key="index" @click="toBidsDetail(item)">
						<div class="name-top">
							<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
								<div class="text-ellipsis">
									{{ item.projectName }}
								</div>
							</el-tooltip>
						</div>
						<div class="bottom-box">
							<div class="tag-bottom">
								<div class="tag-item">招标单位：{{ item.tenderee || "-" }}</div>
								<div class="tag-item">地址：{{ item.city }}</div>
							</div>
							<div class="date">发布时间：{{ item.publishedDate }}</div>
						</div>
					</div>
					<div class="pagination-box">
						<pagination ref="pagination" :total="biddingTotal" @change="biddingPaginChange" v-show="biddingTotal != 0"></pagination>
					</div>
				</div>
				<div v-show="activeName == 'navFour'">
					<div class="history-title">
						<div class="main-title">历史项目数据</div>
						<div class="title-right">
							<div class="table-show" v-show="showType == 1" @click="clickShowType(2)">
								<div class="icon-img"></div>
								<span>表格展示</span>
							</div>
							<div class="atlas-show" v-show="showType == 2" @click="clickShowType(1)">
								<span class="iconfont icon-tupu-"></span>
								<span>图谱展示</span>
							</div>
							<!-- <div class="download-box" @click="toDownload">
								<span class="iconfont icon-download"></span>
								<span>下载</span>
							</div> -->
						</div>
					</div>
					<div class="form-top" style="margin-bottom: 20px">
						<div class="form-lable" style="width: 50px; margin-left: 18px">时间</div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateStart" type="month" :picker-options="startTime" placeholder="选择开始日期" value-format="yyyy-MM"></el-date-picker>
						</div>
						<div class="line"></div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateEnd" type="month" :picker-options="endTime" placeholder="选择结束日期" value-format="yyyy-MM"></el-date-picker>
						</div>
					</div>
					<div class="form-top">
						<div class="form-lable">项目金额</div>
						<el-form ref="conPO" :model="conPO" :rules="rules" style="display: flex; align-items: center">
							<div>
								<el-form-item prop="winnerPriceStart" style="margin-bottom: 0">
									<el-input v-model="conPO.winnerPriceStart" oninput="value=value.replace(/[^0-9.]/g,'')" @blur="conPO.winnerPriceStart = $event.target.value" placeholder="最低金额（万）" @change="blurPrice('start')" clearable></el-input>
								</el-form-item>
							</div>
							<div class="line" style="margin-top: 1px"></div>
							<div>
								<el-form-item prop="winnerPriceEnd" style="margin-bottom: 0">
									<el-input v-model="conPO.winnerPriceEnd" oninput="value=value.replace(/[^0-9.]/g,'')" @blur="conPO.winnerPriceEnd = $event.target.value" placeholder="最高金额（万）" @change="blurPrice('end')" clearable></el-input>
								</el-form-item>
							</div>
						</el-form>
						<div class="note">
							<span class="iconfont icon-zhushibiaozhui"></span>
							<div class="note-title animated fadeIn">
								<span>某金额以上可只填最低金额；某金额以下可只填最高金额</span>
							</div>
						</div>
						<div class="search-button">
							<el-button type="primary" @click="handleQuery">搜索</el-button>
						</div>
					</div>
					<by-tenderee ref="byTenderee"></by-tenderee>
				</div>
				<div v-show="activeName == 'trackRecord'">
					<track-record ref="trackRecord"></track-record>
				</div>
				<div v-show="activeName == 'navFive'">
					<div class="data-title">
						<span>客户资料库</span>
					</div>
					<div class="customer-title-box">
						<div class="title-right" style="width: 110px" @click="handleUpload">
							<img src="@/assets/img/user/upload.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
							<span class="customer">上传资料</span>
						</div>
						<div class="title-right" @click="handleRecord">
							<img src="@/assets/img/user/newfile.png" width="100%" height="100%" @dragstart.prevent class="img-setting" />
							<span class="customer">新建记录文档</span>
						</div>
					</div>
					<div class="datum-box">
						<div class="datum-left">
							<span class="datum-title">资料分类</span>
							<span class="add" @click="handAddType">
								<img src="@/assets/img/user/jia.png" width="14px" height="14px" @dragstart.prevent class="img-setting" />
								<span class="add-style">新增分类</span>
							</span>
							<div class="datum-search">
								<el-input placeholder="请输入分类名称" prefix-icon="el-icon-search" clearable v-model="typeName" @change="typeSearch" @keyup.enter.native="typeSearch" maxlength="10"></el-input>
							</div>
							<div class="file-list">
								<div class="empty-box" v-show="fileList.length == 0" style="margin-top: 40px">
									<empty :name="'暂无数据'"></empty>
								</div>
								<div class="file-item" :class="clickIndex == i ? 'file-item-select' : ''" v-for="(item, i) in fileList" :key="item.folderId" @click="selectFile(i, item)" @mouseover="hoverFile(i)" @mouseout="fileIndex = -1">
									<img class="file-icon" @dragstart.prevent v-show="item.isShare == 0" src="@/assets/img/user/wenjian.png" alt="" />
									<img class="file-icon" @dragstart.prevent v-show="item.isShare == 1" src="@/assets/img/user/share.png" alt="" />
									<div class="file-name">{{ item.folderName }}</div>
									<img class="file-icon" @dragstart.prevent src="@/assets/img/user/xiugai.png" alt="" v-show="(fileIndex == i || clickIndex == i) && item.consumerId && item.isShare == 0" @click.stop="handleEditFile(item)" />
									<img class="file-icon" @dragstart.prevent src="@/assets/img/user/del.png" alt="" style="margin-left: 5px" v-show="(fileIndex == i || clickIndex == i) && item.consumerId && item.isShare == 0" @click.stop="handleDelFile(item.folderId)" />
								</div>
							</div>
							<div class="process">
								<span class="title">存储空间</span>
								<el-progress :percentage="usedSize" :stroke-width="10" :format="format" color="#1181fa"></el-progress>
							</div>
						</div>
						<div class="datum-right">
							<div class="empty-box" v-show="fileTotal == 0" style="margin-top: 150px">
								<empty :name="'暂无数据'"></empty>
							</div>
							<div class="datum-table" v-show="fileTotal != 0">
								<el-table :data="fileTable" header-cell-class-name="table_header" style="width: 100%" height="520">
									<el-table-column prop="fileName" label="文件名称" align="center" show-overflow-tooltip>
										<template slot-scope="scope">
											<span class="click-style" @click="handleViewFile(scope.row)">{{ scope.row.fileName }}</span>
										</template>
									</el-table-column>
									<el-table-column prop="fileSizes" label="文件大小" align="center" show-overflow-tooltip>
										<template slot-scope="scope">
											<span>{{ scope.row.fileSizes }}M</span>
										</template>
									</el-table-column>
									<el-table-column prop="createDate" label="上传时间" align="center" show-overflow-tooltip></el-table-column>
									<el-table-column prop="" label="操作" align="center" width="80">
										<template slot-scope="scope">
											<span class="click-style" @click="handleDelFileDtl(scope.row)">删除</span>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div class="pagination-datum">
								<pagination ref="pagination" :total="fileTotal" @change="paginChange" v-show="fileTotal != 0"></pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<notepad-modal ref="notepadModal"></notepad-modal>
		<!-- 上传资料   dialog -->
		<Modal :visible.sync="uploadVisible" @reset="resetFields" class="dialog-style">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
				{{ uploadTitle }}
			</div>
			<el-form :model="uploadData" :rules="uploadRules" ref="uploadForm" label-width="100px" class="demo-ruleForm">
				<el-row>
					<el-col :span="12">
						<el-form-item label="资料名称" prop="fileName" class="title-item">
							<el-input v-model.trim="uploadData.fileName" placeholder="请输入资料名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所属分类" prop="folderId" class="title-item">
							<el-select v-model="uploadData.folderId" placeholder="请选择所属分类">
								<el-option v-for="item in typeList" :key="item.folderId" :label="item.folderName" :value="item.folderId">{{ item.folderName }}</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="附件上传" prop="file" class="upload-item">
							<el-upload
								:class="{
									uploadFileSty: showBtnDealFile,
									disUploadFileSty: noneBtnFile,
								}"
								ref="uploadReport"
								class="upload-demo"
								drag
								:action="uploadFileUrl"
								:headers="headerObj"
								accept=".pdf, .doc, .docx"
								:multiple="false"
								:limit="1"
								:on-preview="handlePreviewReport"
								:on-change="handleChangeReport"
								:on-success="handleReportSuccess"
								:before-upload="beforeReportUpload"
								:file-list="reportFileList"
								v-loading="uploadLoading"
							>
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">
									<em>点击</em>
									或将文件拖拽到这里上传
								</div>
								<div slot="file" slot-scope="{file}">
									<div class="file-upload-style" v-show="noneBtnFile" @click="handlePreviewReport(file)">
										<div class="file-img">
											<img :src="filePic" alt="" />
										</div>
										<span class="fille-name">{{ file.name }}</span>
										<i class="el-icon-close card-panel-icon icon-style" @click.stop="handleRemoveReport(file)" />
									</div>
								</div>
								<div class="el-upload__tip" slot="tip" v-show="!noneBtnFile">
									只能上传一份文件，支持pdf/doc/docx格式
									<div>文件大小不超过50M</div>
								</div>
							</el-upload>
						</el-form-item>
					</el-col>

					<el-col>
						<el-form-item class="btn-item">
							<el-button @click="cancel()">取消</el-button>
							<el-button type="primary" @click="submitForm('uploadForm')" :loading="submitUploadLoading">确认</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</Modal>
		<!-- 新建记录文档   dialog -->
		<Modal :visible.sync="recordVisible" @reset="resetRecordFields" class="dialog-file-style">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
				{{ recordTitle }}
			</div>
			<el-form :model="recordData" :rules="recordRules" ref="recordForm" label-width="100px" class="demo-ruleForm">
				<el-row>
					<el-col :span="12">
						<el-form-item label="文档名称" prop="fileName" class="title-item">
							<el-input v-model.trim="recordData.fileName" placeholder="请输入文档名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所属分类" prop="folderId" class="title-item">
							<el-select v-model="recordData.folderId" placeholder="请选择所属分类">
								<el-option v-for="item in typeList" :key="item.folderId" :label="item.folderName" :value="item.folderId">{{ item.folderName }}</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="文本内容" prop="detail" class="upload-item">
							<!-- 富文本 -->
							<editor v-model="recordData.detail" :height="240" style="width: 593px; over-flow: auto" ref="editor" @on-change="handleEditorChange" />
							<span class="txt-style" @click="handleView()">预览</span>
						</el-form-item>
					</el-col>

					<el-col>
						<el-form-item class="btn-item">
							<el-button @click="cancelRecord()">取消</el-button>
							<el-button type="primary" @click="submitRecordForm('recordForm')" :loading="submitRecordLoading">确认</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</Modal>
		<!-- 文档预览  dialog -->
		<Modal :visible.sync="quillVisible" class="dialog-view-style">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">文档预览</div>
			<div class="report-preview ql-editor" id="detailPreview"></div>
		</Modal>
		<!-- 添加分类   dialog -->
		<Modal :visible.sync="typeVisible" @reset="resetTypeFields" class="dialog-type-style">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
				{{ typeTitle }}
			</div>
			<el-form :model="typeData" :rules="typeRules" ref="typeForm" label-width="100px" class="demo-ruleForm">
				<el-row>
					<el-col :span="24">
						<el-form-item label="" prop="folderName" class="title-item">
							<el-input v-model.trim="typeData.folderName" placeholder="请输入分类名称" maxlength="10" :show-word-limit="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col>
						<el-form-item class="btn-item">
							<el-button @click="cancelType()">取消</el-button>
							<el-button type="primary" @click="submitTypeForm('typeForm')" :loading="submitTypeLoading">确认</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</Modal>
		<!-- 修改文件夹名称  dialog -->
		<Modal :visible.sync="fileVisible" @reset="resetFileFields" class="dialog-type-style">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
				{{ fileTitle }}
			</div>
			<el-form :model="fileData" :rules="fileRules" ref="fileForm" label-width="100px" class="demo-ruleForm">
				<el-row>
					<el-col :span="24">
						<el-form-item label="" prop="folderName" class="title-item">
							<el-input v-model.trim="fileData.folderName" placeholder="请输入文件夹名称" maxlength="30" :show-word-limit="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col>
						<el-form-item class="btn-item">
							<el-button @click="cancelFile()">取消</el-button>
							<el-button type="primary" @click="submitFileForm('fileForm')" :loading="submitFileLoading">确认</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</Modal>
		<!-- 富文本预览  dialog -->
		<Modal :visible.sync="dtlVisible" class="dialog-view-style">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">文档预览</div>
			<div class="report-preview ql-editor" id="dtlPreview"></div>
		</Modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import Editor from "@/components/Editor";
// import { validateTax } from "@/utils/validate.js";
import ByTenderee from "./byTenderee";
import PersonInfo from "./personInfo";
import TrackRecord from "./trackRecord";
import html2canvas from "html2canvas";
import NotepadModal from "@/components/notepadModal";
import RelationGraph from "relation-graph";
export default {
	components: {
		Pagination,
		LoginModal,
		MemberModal,
		Empty,
		Modal,
		ByTenderee,
		NotepadModal,
		PersonInfo,
		TrackRecord,
		Editor,
		RelationGraph,
	},
	data() {
		let price = (rule, value, callback) => {
			if (!value) {
				callback();
			} else {
				const reg = /^[0-9]{1,18}([.][0-9]{1,6})?$/;
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error("最多18位整数，6位小数"));
				}
			}
		};
		return {
			graphOptions: {
				// allowShowMiniToolBar: false,
				allowShowMiniNameFilter: false,
				allowSwitchLineShape: false,
				allowSwitchJunctionPoint: false,
				defaultFocusRootNode: false,
				disableZoom: true,
				layouts: [
					{
						label: "自动布局",
						layoutName: "force",
						layoutClassName: "seeks-layout-force",
					},
				],
			},
			id: "",
			type: "",
			activeName: "navOne",
			mdsClientInfo: {
				noteCount: 0,
			},
			detail: {}, // 基本信息  工商信息
			clientBasic: {}, // 工商信息
			mdsClientActualOwner: "",
			shareholderPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			shareholderTotal: 0,
			shareholderList: [], // 股东信息
			principalPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			principalTotal: 0,
			principalList: [], // 主要人员
			investmentPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			investmentTotal: 0,
			investmentList: [], // 对外投资
			controlsPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			controlsTotal: 0,
			controlsList: [], // 实际控制企业
			branchesPo: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			branchesTotal: 0,
			branchesList: [], // 分支机构

			showType: 1, // 1 展示图谱 2 展示表格
			conPO: {
				tenderee: "",
				winnerPriceStart: "",
				winnerPriceEnd: "",
				dateStart: "",
				dateEnd: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			}, // 查询条件
			params: {},
			rules: {
				winnerPriceStart: [
					{
						validator: price,
						trigger: "blur",
					},
				],
				winnerPriceEnd: [
					{
						validator: price,
						trigger: "blur",
					},
				],
			},
			startTime: {
				disabledDate: time => {
					if (this.conPO.dateEnd) {
						return time.getTime() > new Date(this.conPO.dateEnd).getTime();
					}
				},
			}, // 控制开始时间
			endTime: {
				disabledDate: time => {
					if (this.conPO.dateStart) {
						return time.getTime() < new Date(this.conPO.dateStart).getTime();
					}
				},
			}, // 控制结束时间

			dynamicTotal: 0,
			dynamicStart: "",
			dynamicEnd: "",
			dynamicStartTime: {
				disabledDate: time => {
					if (this.dynamicEnd) {
						return time.getTime() > new Date(this.dynamicEnd).getTime();
					}
				},
			},
			dynamicEndTime: {
				disabledDate: time => {
					if (this.dynamicStart) {
						return time.getTime() < new Date(this.dynamicStart).getTime();
					}
				},
			},
			dynamicPO: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			pageDynamic: [],
			bidsStart: "",
			bidsEnd: "",
			bidsStartTime: {
				disabledDate: time => {
					if (this.bidsEnd) {
						return time.getTime() > new Date(this.bidsEnd).getTime();
					}
				},
			},
			bidsEndTime: {
				disabledDate: time => {
					if (this.bidsStart) {
						return time.getTime() < new Date(this.bidsStart).getTime();
					}
				},
			},
			bidInput: "",
			biddingTotal: 0,
			bidsPO: {
				platform: 1,
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			pageBidding: [],

			typeName: "",
			fileIndex: 0,
			clickIndex: 0,
			fileList: [],
			typeList: [],
			fileTable: [],
			fileTotal: 0,
			// 存储
			usedSize: 0,
			spaceSize: 0,
			filePO: {
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			folderId: "",
			// 以下弹窗字段
			uploadVisible: false,
			uploadTitle: "上传资料",
			uploadLoading: false,
			uploadData: {},
			uploadFileUrl: process.env.VUE_APP_BASE_URL + "/mds/interceptApi/consumerClient/uploadArchivesFile", // 上传PDF、WORD服务器地址
			headerObj: {
				Authorization: this.$session.getLoginToken(),
			},
			reportUrl: "",
			reportFileList: [],
			showBtnDealFile: true,
			noneBtnFile: false,
			filePic: "",
			uploadList: [],
			uploadRules: {
				name: [{required: true, message: "请输入客户名称", trigger: "blur"}],
				allContent: [{required: true, message: "请选择订阅内容", trigger: "change"}],
			},
			submitUploadLoading: false,
			recordVisible: false,
			recordTitle: "新建记录文档",
			recordData: {},
			recordRules: {
				name: [{required: true, message: "请输入客户名称", trigger: "blur"}],
				allContent: [{required: true, message: "请选择订阅内容", trigger: "change"}],
			},
			submitRecordLoading: false,
			typeVisible: false,
			typeTitle: "添加分类",
			typeData: {},
			typeRules: {
				name: [{required: true, message: "请输入客户名称", trigger: "blur"}],
			},
			submitTypeLoading: false,
			fileVisible: false,
			fileTitle: "修改文件夹名称",
			fileData: {},
			fileRules: {
				name: [{required: true, message: "请输入文件夹名称", trigger: "blur"}],
			},
			submitFileLoading: false,
			quillVisible: false,
			hoverFlag: false,
			dtlVisible: false,
		};
	},
	mounted() {
		let id = this.$route.query.id;
		this.id = id;
		let type = this.$route.query.type;
		this.type = type;
		this.getClientInfoDetail();
		if (type == 1) {
			// 企业客户
			this.getClientInfoDetailShareholder();
			this.getClientInfoDetailPrincipalStaff();
			this.getClientInfoDetailInvestment();
			this.getClientInfoDetailActualControls();
			this.getClientInfoDetailBranches();
		} else {
			// 非企业客户
			this.activeName = "personInfo";
            this.$refs.personInfo.getClientStaff();
		}
	},
	methods: {
		coloring() {
			let colors = ["#90cfb7", "#8ca3cc", "#e8ab6f", "#6daae2", "#c8b7d8"];
			let index = Math.floor(Math.random() * 5);
			return colors[index];
		},
		// 判断经营状态
		getNewStatus(type) {
			if (type == 1) {
				return "存续";
			} else if (type == 2) {
				return "注销";
			} else if (type == 3) {
				return "吊销";
			} else if (type == 4) {
				return "撤销";
			} else if (type == 5) {
				return "迁出";
			} else if (type == 6) {
				return "设立中";
			} else if (type == 7) {
				return "清算中";
			} else if (type == 8) {
				return "停业";
			} else if (type == 9) {
				return "其他";
			}
		},
		toMember() {
			let url = this.$router.resolve({
				path: `/member`,
			});
			window.open(url.href, "_blank");
		},
		// 切换tab栏前
		beforeleaveNav(activeName) {
			if (activeName == "navTwo") {
                this.getClientInfoDynamic();
            } else if (activeName == "navThree") {
                this.getClientInfoTenderInfo();
            } else if (activeName == "navFour") {
                let {conPO, detail, id} = this;
                conPO.clientId = id;
                conPO.tenderee = detail.clientName;
                this.$refs.byTenderee.getParams(conPO, 1);
            } else if (activeName == "navFive") {
                this.getClientInfoArchivesFolder();
            } else if (activeName == "personInfo") {
                this.$refs.personInfo.getClientStaff();
            } else if (activeName == "trackRecord") {
                this.$refs.trackRecord.getClientProject();
            }
		},
		// 获取基本信息
		getClientInfoDetail() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			this.$api.industry
				.getClientInfoDetail({
					clientId: this.id,
				})
				.then(res => {
					let data = res.data;
					this.mdsClientInfo.noteCount = data.mdsClientInfo.noteCount;
					if (!data.mdsClientInfo.logoUrl) {
						this.$api.industry
							.getCompanyName({
								companyName: data.mdsClientInfo.clientName,
							})
							.then(ress => {
								data.mdsClientInfo.logoImg = ress.msg;
								// data.mdsClientInfo.bcColor = this.coloring();
								this.$forceUpdate();
							})
							.catch(msg => {
								if (msg?.msg) {
									this.$message.error(msg?.msg);
								}
							});
					}
					this.detail = data.mdsClientInfo;
					if (data.clientBasic) {
						this.clientBasic = data.clientBasic;
					}
					if (data.mdsClientActualOwner) {
						let owner = data.mdsClientActualOwner;
						this.mdsClientActualOwner = owner;
						let nodes = JSON.parse(owner.nodes);
						let links = JSON.parse(owner.links);
						nodes.forEach(item => {
							item.text = item.name;
							item.color = "#1181FA";
							item.fontColor = "#fff";
							item.nodeShape = 1;
							item.borderColor = "transparent";
							delete item.flated;
							delete item.isKey;
							delete item.name;
							delete item.type;
							delete item.uid;
						});
						links.forEach(item => {
							item.text = item.properties[0].relationPercent;
							item.from = item.sourceId + "";
							item.to = item.targetId + "";
							item.lineWidth = 2;
							delete item.properties;
							delete item.sourceId;
							delete item.targetId;
							delete item.type;
						});

						let __graph_json_data = {
							rootId: owner.actualOwnerId,
							nodes,
							links,
						};
						console.log(__graph_json_data);
						this.$refs.seeksRelationGraph.setJsonData(__graph_json_data);
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 获取股东信息
		getClientInfoDetailShareholder() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});

			let {shareholderPo, id} = this;
			shareholderPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailShareholder(shareholderPo)
				.then(res => {
					let shareholderList = res.rows;
					shareholderList.forEach((item, index) => {
						item.index = (shareholderPo.pageNum - 1) * shareholderPo.pageSize + index + 1;
					});
					this.shareholderList = shareholderList;
					this.shareholderTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 股东信息分页
		shareholderPaginChange(page, pageSize) {
			this.shareholderPo.pageNum = page;
			this.shareholderPo.pageSize = pageSize;
			this.getClientInfoDetailShareholder();
		},
		// 获取主要人员
		getClientInfoDetailPrincipalStaff() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});

			let {principalPo, id} = this;
			principalPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailPrincipalStaff(principalPo)
				.then(res => {
					let principalList = res.rows;
					principalList.forEach((item, index) => {
						item.index = (principalPo.pageNum - 1) * principalPo.pageSize + index + 1;
					});
					this.principalList = principalList;
					this.principalTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 主要人员分页
		principalPaginChange(page, pageSize) {
			this.principalPo.pageNum = page;
			this.principalPo.pageSize = pageSize;
			this.getClientInfoDetailPrincipalStaff();
		},
		// 获取企业对外投资
		getClientInfoDetailInvestment() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});

			let {investmentPo, id} = this;
			investmentPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailInvestment(investmentPo)
				.then(res => {
					let investmentList = res.rows;
					investmentList.forEach((item, index) => {
						item.index = (investmentPo.pageNum - 1) * investmentPo.pageSize + index + 1;
					});
					this.investmentList = investmentList;
					this.investmentTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 企业对外投资分页
		investmentPaginChange(page, pageSize) {
			this.investmentPo.pageNum = page;
			this.investmentPo.pageSize = pageSize;
			this.getClientInfoDetailInvestment();
		},
		// 获取实际控制企业
		getClientInfoDetailActualControls() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});

			let {controlsPo, id} = this;
			controlsPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailActualControls(controlsPo)
				.then(res => {
					let controlsList = res.rows;
					controlsList.forEach((item, index) => {
						item.index = (controlsPo.pageNum - 1) * controlsPo.pageSize + index + 1;
					});
					this.controlsList = controlsList;
					this.controlsTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 实际控制企业分页
		controlsPaginChange(page, pageSize) {
			this.controlsPo.pageNum = page;
			this.controlsPo.pageSize = pageSize;
			this.getClientInfoDetailActualControls();
		},
		// 获取分支机构
		getClientInfoDetailBranches() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});

			let {branchesPo, id} = this;
			branchesPo.clientId = id;
			this.$api.industry
				.getClientInfoDetailBranches(branchesPo)
				.then(res => {
					let branchesList = res.rows;
					branchesList.forEach((item, index) => {
						item.index = (branchesPo.pageNum - 1) * branchesPo.pageSize + index + 1;
					});
					this.branchesList = branchesList;
					this.branchesTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 分支机构分页
		branchesPaginChange(page, pageSize) {
			this.branchesPo.pageNum = page;
			this.branchesPo.pageSize = pageSize;
			this.getClientInfoDetailBranches();
		},
		// 点击记事本
		toNotepad() {
			this.$api.industry
				.getClientInfoNotePad({
					clientId: this.id,
				})
				.then(res => {
					this.$refs.notepadModal.list = res.data;
					this.$refs.notepadModal.content = "";
					this.$refs.notepadModal.activeName = "navOne";
					this.$refs.notepadModal.id = this.id;
					this.$refs.notepadModal.visible = true;
					this.$refs.notepadModal.editType = false;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 去官网
		goWeb(val) {
			this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				window.open(val, "_blank");
			});
		},
		// 加入-移除客户列表
		joinMyClient(type) {
			this.$api.industry
				.joinMyClient({
					clientId: this.id,
				})
				.then(() => {
					if (type == 1) {
						this.$message.success("已加入客户列表");
						this.detail.isJoin = 1;
					} else {
						this.$message.success("已从客户列表移除");
						this.detail.isJoin = 0;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 订阅-取消订阅
		jsubscribeClient(type) {
			this.$api.industry
				.jsubscribeClient({
					clientId: this.id,
				})
				.then(() => {
					if (type == 1) {
						this.$message.success("加入订阅成功");
						this.detail.isJsubscribe = 1;
					} else {
						this.$message.success("已取消订阅");
						this.detail.isJsubscribe = 0;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},

		// 客户动态搜索
		dynamicQuery() {
			this.dynamicPO.pageNum = 1;
			this.dynamicPO.pageSize = 10;
			this.getClientInfoDynamic();
		},
		// 获取客户动态
		getClientInfoDynamic() {
			let {dynamicPO, dynamicStart, dynamicEnd, id} = this;
			dynamicPO.clientId = id;
			dynamicPO.dateStart = dynamicStart;
			dynamicPO.dateEnd = dynamicEnd;
			this.$api.industry
				.getClientInfoDynamic(dynamicPO)
				.then(res => {
					this.pageDynamic = res.rows;
					this.dynamicTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 客户动态分页
		dynamicPaginChange(page, pageSize) {
			this.dynamicPO.pageNum = page;
			this.dynamicPO.pageSize = pageSize;
			this.getClientInfoDynamic();
		},
		// 点击进入客户动态详情
		toDynamicDetail(id) {
			let url = this.$router.resolve({
				path: `/industry/dynamic-detail?id=${id}&clientId=${this.id}`,
			});
			window.open(url.href, "_blank");
		},
		// 招标动态搜索
		bidsQuery() {
			this.bidsPO.pageNum = 1;
			this.bidsPO.pageSize = 10;
			this.getClientInfoTenderInfo();
		},
		// 获取招标动态
		getClientInfoTenderInfo() {
			let {bidsPO, bidsStart, bidsEnd, detail, bidInput} = this;
			bidsPO.tenderee = detail.clientName;
			bidsPO.dateStart = bidsStart;
			bidsPO.dateEnd = bidsEnd;
			bidsPO.search = bidInput;
			this.$api.industry
				.getClientInfoTenderInfo(bidsPO)
				.then(res => {
					this.pageBidding = res.rows;
					this.biddingTotal = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 招标动态分页
		biddingPaginChange(page, pageSize) {
			this.bidsPO.pageNum = page;
			this.bidsPO.pageSize = pageSize;
			this.getClientInfoTenderInfo();
		},
		// 点击进入招标动态详情
		toBidsDetail(item) {
			let url = this.$router.resolve({
				path: `/industry/bidding-detail?id=${item.tenderId}`,
			});
			window.open(url.href, "_blank");
		},
		toIndividual(scope) {
			console.log(scope);
		},
		paginChange(pageNum, pageSize) {
			this.filePO.pageNum = pageNum;
			this.filePO.pageSize = pageSize;
			this.getClientInfoArchivesFile(this.folderId);
		},
		// 点击表格展示或图谱展示
		clickShowType(type) {
			if (!this.params) {
				// 没点搜索时
				return;
			}
			let {conPO} = this;
			this.showType = type;
			if (type == 1) {
				this.$refs.byTenderee.getParams(conPO, 1, "切换");
			} else {
				this.$refs.byTenderee.getParams(conPO, 2);
			}
		},
		// 下载
		toDownload() {
			let {params, showType} = this;
            let paramsObj = JSON.parse(JSON.stringify(params));
            delete paramsObj.pageNum;
            delete paramsObj.pageSize;
            if (showType == 1) {
                // 图谱下载
                this.$api.successful
                    .exportStaTenderee()
                    .then(() => {
                        this.exportImg(document.getElementById("bidwinner"), "统计数据-按中标人", "png");
                    })
                    .catch(msg => {
                        if (msg?.msg) {
                            this.$message.error(msg?.msg);
                        }
                    });
            } else {
                // 表格下载
                const loading = this.$loading({
                    lock: true,
                    text: "导出中...",
                    spinner: "el-icon-loading",
                    background: "rgba(255, 255, 255, 0.3)",
                    fullscreen: false,
                });
                this.$api.successful
                    .exportStaBidWinnerByTenderee(paramsObj)
                    .then(res => {
                        this.$utils.downloadFile(res, "统计数据-按中标人.xlsx");
                    })
                    .catch(msg => {
                        if (msg?.msg) {
                            this.$message.error(msg?.msg);
                        }
                    })
                    .finally(() => {
                        loading.close();
                    });
            }
		},
		/*
		 * element  导出的元素
		 * filename 文件名
		 * ext      扩展文件名
		 */
		exportImg(element, filename, ext) {
			const loading = this.$loading({
				lock: true,
				text: "导出中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			html2canvas(element, {
				useCORS: true,
				height: element.scrollHeight,
				windowHeight: element.scrollHeight,
				width: element.width,
				windowWidth: element.width,
			}).then(canvas => {
				loading.close();
				if (navigator.msSaveBlob) {
					const blob = canvas.msToBlob(); // IE10+
					return navigator.msSaveBlob(blob, name);
				} else {
					const imageurl = canvas.toDataURL("image/png");
					const aLink = document.createElement("a"); // 创建a标签
					aLink.style.display = "none";
					aLink.href = imageurl;
					aLink.download = `${filename}.${ext}`; // 下载文件名
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); // 用完后移除元素
				}
			});
		},
		// 点击历史项目数据中的搜索
		handleQuery() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let {conPO} = this;
                let params = JSON.parse(JSON.stringify(conPO));
                this.params = params;
                this.showType = 1;
                delete params.pageSize;
                delete params.pageNum;
                this.$refs.byTenderee.getParams(params, 1);
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		selectFile(i, item) {
			this.clickIndex = i;
			this.folderId = item.folderId;
			this.getClientInfoArchivesFile(this.folderId);
		},

		hoverFile(i) {
			// console.log(i);
			this.fileIndex = i;
		},

		// 资料报告库  进度条自定义文字
		format(usedSize) {
			return `${usedSize}M/${this.spaceSize}M`;
		},
		// 资料报告库  上传资料按钮
		handleUpload() {
			this.uploadVisible = true;
			this.uploadTitle = "上传资料";
			this.resetFields();
			this.noneBtnFile = false;
			this.reportFileList = [];
		},
		// 资料报告库  新建记录文档按钮
		handleRecord() {
			this.recordVisible = true;
			this.recordTitle = "新建记录文档";
			this.resetRecordFields();
		},
		// 资料报告库  添加分类按钮
		handAddType() {
			this.typeVisible = true;
			this.typeTitle = "添加分类";
			this.resetTypeFields();
		},

		// 资料报告库  关闭弹窗时重置表单
		resetFields() {
			// this.$refs.uploadForm.resetFields();
			this.uploadData = {};
		},
		resetTypeFields() {
			// this.$refs.typeForm.resetFields();
			this.typeData = {};
		},
		resetRecordFields() {
			// this.$refs.recordForm.resetFields();
			this.recordData = {};
		},
		resetFileFields() {
			// this.$refs.fileForm.resetFields();
			this.fileData = {};
		},
		// 资料报告库  富文本编辑事件
		handleEditorChange(val) {
			this.recordData.detail = val.html;
		},
		// 资料报告库  预览富文本
		handleView() {
			this.quillVisible = true;
			if (this.recordData.detail == undefined) {
				this.recordData.detail = "";
			}
			setTimeout(() => {
				document.getElementById("detailPreview").innerHTML = this.recordData.detail;
			}, 100);
		},
		// 资料报告库  所有表单提交事件
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.personalCenter
						.addClientArchivesFile({
							...this.uploadData,
							clientId: this.id,
							uploadWay: "60471002",
						})
						.then(res => {
							this.uploadVisible = false;
							this.$message.success("提交成功");
							this.getClientInfoArchivesFolder();
						})
						.catch(err => {
							this.$message.error("提交失败");
						});
				} else {
					this.$message.error("提交失败!");
					return false;
				}
			});
		},
		submitRecordForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.personalCenter
						.addClientArchivesFile({
							...this.recordData,
							clientId: this.id,
							uploadWay: "60471001",
						})
						.then(res => {
							this.recordVisible = false;
							this.$message.success("提交成功");
							this.getClientInfoArchivesFolder();
						})
						.catch(err => {
							this.$message.error("提交失败");
						});
				} else {
					this.$message.error("提交失败!");
					return false;
				}
			});
		},
		submitTypeForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.personalCenter
						.addClientArchivesFolder({
							...this.typeData,
							clientId: this.id,
						})
						.then(res => {
							this.typeVisible = false;
							this.$message.success("提交成功");
							this.getClientInfoArchivesFolder();
						})
						.catch(err => {
							this.$message.error("提交失败");
						});
				} else {
					this.$message.error("提交失败!");
					return false;
				}
			});
		},
		submitFileForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.personalCenter
						.updateClientArchivesFolder({...this.fileData})
						.then(res => {
							this.fileVisible = false;
							this.$message.success("提交成功");
							this.getClientInfoArchivesFolder();
						})
						.catch(err => {
							this.$message.error("提交失败");
						});
				} else {
					this.$message.error("提交失败!");
					return false;
				}
			});
		},
		// 资料报告库  表单取消
		cancel() {
			this.uploadVisible = false;
			this.resetFields();
		},
		cancelRecord() {
			this.recordVisible = false;
			this.resetRecordFields();
		},
		cancelType() {
			this.typeVisible = false;
			this.resetTypeFields();
		},
		cancelFile() {
			this.fileVisible = false;
			this.resetFileFields();
		},
		// 资料分类搜索
		typeSearch() {
			this.fileList = [];
			this.fileIndex = 0;
			this.getClientInfoArchivesFolder();
		},
		// 资料报告库   获取左侧文件夹列表
		getClientInfoArchivesFolder() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			this.$api.personalCenter
				.getClientInfoArchivesFolder({
					clientId: this.id,
					folderName: this.typeName,
				})
				.then(res => {
					this.fileList = res.data;
					loading.close();
					if (res.data.length != 0) {
						this.clickIndex = 0;
						this.folderId = this.fileList[0].folderId;
						this.getClientInfoArchivesFile(this.fileList[0].folderId);
					}
					this.getClientSpace();
				})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
			this.$api.personalCenter
				.myClientInfoArchivesFolder({
					clientId: this.id,
				})
				.then(res => {
					this.typeList = res.data;
				})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 资料报告库  编辑左侧文件夹
		handleEditFile(row) {
			this.fileVisible = true;
			this.fileTitle = "修改文件夹名称";
			this.fileData = JSON.parse(JSON.stringify(row));
		},
		// 资料报告库  删除左侧文件夹
		handleDelFile(val) {
			this.$confirm("确认删除该文件夹吗？", "提示", {
				confirmButtonText: "确认删除",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$api.personalCenter
						.deleteClientArchivesFolder({folderId: val})
						.then(res => {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.getClientInfoArchivesFolder();
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				})
				.catch(() => {});
		},
		// 资料报告库  获取存储空间
		getClientSpace() {
			this.$api.personalCenter
				.getClientSpace()
				.then(res => {
					this.spaceSize = Number((res.data.spaceSize / 1024 / 1024).toFixed(2));
					this.usedSize = Number((res.data.usedSize / 1024 / 1024).toFixed(2));
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 资料报告库  获取右侧文件列表
		getClientInfoArchivesFile(val) {
			this.$api.personalCenter
				.getClientInfoArchivesFile({
					folderId: val || this.folderId,
					clientId: this.id,
					...this.filePO,
				})
				.then(res => {
					this.fileTable = res.data.data;
					this.fileTotal = res.data.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 资料报告库  右侧文件查看
		handleViewFile(row) {
			if (row.uploadWay == "60471001") {
				// 富文本
				this.$api.personalCenter
					.getFileDtl(row.folderFileId)
					.then(res => {
						this.dtlVisible = true;
						setTimeout(() => {
							document.getElementById("dtlPreview").innerHTML = res.data.detail;
						}, 100);
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				// 文件
				this.$api.personalCenter
					.getFileInfo({fileId: row.fileId})
					.then(res => {
						let url = process.env.VUE_APP_FILEURL + res.data.fileDir + "/" + res.data.fileKey;
						window.open(url, "_blank");
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			}
		},
		// 资料报告库  删除右侧文件
		handleDelFileDtl(row) {
			this.$confirm("确认该文件吗？", "提示", {
				confirmButtonText: "确认删除",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$api.personalCenter
						.deleteFile(row.folderFileId)
						.then(res => {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.getClientInfoArchivesFolder();
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				})
				.catch(() => {});
		},
		// 资料报告库  上传资料
		handleChangeReport(file, fileList) {},
		handleReportSuccess(res, file) {
			if (res.code == 200) {
				this.noneBtnFile = true;
				this.uploadLoading = false;
				this.$message.success("上传成功");
				this.uploadData.fileId = res.data.fileId;
				this.uploadData.fileSize = res.data.fileLength;
				this.$refs.uploadForm.clearValidate("fileId");
				this.reportUrl = res.data.url;
				this.filePic = require("../../../../../assets/img/file/" + res.data.fileExtension.toLowerCase() + ".png");
			} else {
				this.$refs.uploadReport.handleRemove(file);
				this.noneBtnFile = false;
				this.uploadLoading = false;
				this.$message.error(res.msg);
			}
		},
		beforeReportUpload(file) {
			this.noneBtnFile = true;
			this.uploadLoading = true;
			let name = file.name;
			let namearr = name.split(".");
			if (namearr.length > 2) {
				this.uploadLoading = false;
				this.$message.error("文件名称不能携带特殊字符！");
				this.noneBtnFile = false;
				return false;
			} else if (name.toLowerCase().indexOf("pdf") == -1 && name.toLowerCase().indexOf("doc") == -1 && name.toLowerCase().indexOf("docx") == -1) {
				this.uploadLoading = false;
				this.$message.error("请上传pdf/doc/docx格式文件！");
				this.noneBtnFile = false;
				return false;
			} else {
				let a = file.name.lastIndexOf(".");
				let d = file.name.toLowerCase().substring(a + 1, file.name.length);
				this.filePic = require("../../../../../assets/img/file/" + d + ".png");
				const isLt50M = file.size / 1024 / 1024 < 50;
				if (!isLt50M) {
					this.uploadLoading = false;
					this.$message.error("上传文件大小不能超过50M!");
					this.noneBtnFile = false;
				}
				return isLt50M;
			}
		},
		handlePreviewReport(file) {
			window.open(this.reportUrl, "_blank");
		},
		handleRemoveReport(file, fileList) {
			this.$refs.uploadReport.handleRemove(file);
			this.uploadData.fileId = "";
			this.noneBtnFile = false;
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
