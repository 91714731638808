<template>
  <div class="customer-main">
    <div class="customer-title-box">
      <div>项目跟踪记录</div>
    </div>
    <!-- <div class="divider"></div> -->
    <div class="search">
      <div class="search-left">
        <span class="search-title">项目名称</span>
        <el-input
          class="search-input"
          v-model.trim="keywords"
          placeholder="请输入项目名称关键字"
          clearable
        ></el-input>
      </div>
      <div class="search-right">
        <div class="right-top">
          <el-button type="primary" @click="search()">搜索</el-button>
        </div>
        <div class="right-bottom">
          <div class="btn">
            <span class="btn-style">
              <el-button type="primary" icon="el-icon-plus" @click="handleAdd()"
                >新增项目</el-button
              >
            </span>
            <el-button
              type="primary"
              icon="el-icon-edit"
              :disabled="single"
              @click="handleEdit()"
              >编辑</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-delete"
              :disabled="multiple"
              @click="handleDel()"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="customer-content">
      <div v-if="total == 0" style="margin-top: 150px">
        <empty name="暂无数据"></empty>
      </div>
      <div class="customer-box" v-show="total != 0">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table_header"
          height="645px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column prop="" label="操作" width="80" align="center">
            <template slot-scope="scope">
              <span class="view-style" @click="goDetail(scope.row.projectId)"
                >跟踪详情</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="projectName"
            label="项目名称"
            width="160"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="projectTypeName"
            label="项目类型"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="constructionContent"
            label="建设内容"
            width="140"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="decisionMakerInfo"
            label="决策人信息"
            width="140"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{
              scope.row.decisionMakerInfo || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            prop="contactInfo"
            label="联络人信息"
            width="140"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.contactInfo || "--" }}</template>
          </el-table-column>
          <el-table-column
            prop="competitor"
            label="潜在/主要竞争者"
            width="140"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.competitor || "--" }}</template>
          </el-table-column>
          <el-table-column
            prop="unionResources"
            label="可联合资源"
            width="140"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.unionResources || "--" }}</template>
          </el-table-column>
          <el-table-column
            prop="remarks"
            label="备注"
            width="160"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.remarks || "--" }}</template>
          </el-table-column>
          <el-table-column
            prop="updateDate"
            label="最近更新时间"
            width="160"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">{{ scope.row.updateDate || "-" }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        >
        </pagination>
      </div>
    </div>
    <!-- 新增项目   dialog -->
    <Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        {{ title }}
      </div>
      <el-form
        :model="dataPO"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称" prop="projectName" class="title-item">
              <el-input
                v-model.trim="dataPO.projectName"
                placeholder="请输入项目名称"
                maxlength="50"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目类型" prop="projectType" class="title-item">
              <el-select v-model="dataPO.projectType" placeholder="请选择项目类型">
                <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item.categoryName"
                  :value="item.categoryId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="建设内容" prop="constructionContent" class="title-item">
              <el-input
                type="textarea"
                v-model.trim="dataPO.constructionContent"
                placeholder="请输入建设内容，限200字"
                :rows="4"
                maxlength="200"
                :show-word-limit="true"
                resize="none"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="投资额" prop="investmentAmount" class="title-item">
              <el-input
                v-model.trim="dataPO.investmentAmount"
                placeholder="请输入投资额"
              ></el-input>
              <span class="money">万元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="决策人信息" prop="decisionMakerInfo" class="title-item">
              <el-input
                v-model.trim="dataPO.decisionMakerInfo"
                placeholder="请输入决策人信息"
                maxlength="50"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联络人信息" prop="contactInfo" class="title-item">
              <el-input
                v-model.trim="dataPO.contactInfo"
                placeholder="请输入联络人信息"
                maxlength="50"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主要/潜在竞争者" prop="competitor" class="title-item">
              <el-input
                v-model.trim="dataPO.competitor"
                placeholder="请输入主要/潜在竞争者"
                maxlength="50"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="可联合资源" prop="unionResources" class="title-item">
              <el-input
                v-model.trim="dataPO.unionResources"
                placeholder="请输入可联合资源"
                maxlength="100"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks" class="title-item">
              <el-input
                type="textarea"
                v-model.trim="dataPO.remarks"
                placeholder="请输入备注信息，限200字"
                :rows="8"
                :show-word-limit="true"
                maxlength="200"
                resize="none"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item class="btn-item">
              <el-button @click="cancel()">取消</el-button>
              <el-button type="primary" @click="submitForm('form')">确认</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
export default {
  components: {
    Pagination,
    Empty,
    Modal,
  },
  data() {
    return {
      total: 0,
      keywords: "",
      typeList: [],
      tableData: [],
      visible: false,
      title: "新增项目",
      dataPO: {},
      rules: {
        projectName: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        projectType: [{ required: true, message: "请选择项目类型", trigger: "change" }],
        constructionContent: [
          { required: true, message: "请输入建设内容", trigger: "blur" },
        ],
        investmentAmount: [{ required: true, message: "请输入投资额", trigger: "blur" }],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      editData: {},
    };
  },
  computed: {},
  created() {
    this.getProjectType();
  },
  mounted() {
    // this.getClientProject();
  },
  methods: {
    // 保留两位小数方法
    calcNum(val) {
      return Number(val).toFixed(2);
    },
    // 获取列表
    getClientProject() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: true,
      });
      this.$api.personalCenter
        .getClientProject({
          clientId: this.$route.query.id,
          projectName: this.keywords,
          ...this.params,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          res.data.data.forEach((item) => {
            this.typeList.forEach((items) => {
              if (item.projectType == items.categoryId) {
                item.projectTypeName = items.categoryName;
              }
            });
          });
          loading.close();
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
          loading.close();
        });
    },
    // 获取类型列表
    getProjectType() {
      this.$api.personalCenter
        .getProjectType()
        .then((res) => {
          this.typeList = res.data;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },

    // 搜索
    search() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getClientProject();
    },
    // 复选框选择事件
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.projectId);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
      if (!this.single) {
        this.editData = selection[0];
      }
    },
    // 新建成员按钮
    handleAdd() {
      this.visible = true;
      this.title = "新增项目";
      // this.resetFields();
    },
    // 修改按钮
    handleEdit() {
      this.visible = true;
      this.title = "修改项目";
      this.dataPO = JSON.parse(JSON.stringify(this.editData));
      this.typeList.forEach((item) => {
        if (this.dataPO.projectTypeName == item.categoryName) {
          this.dataPO.projectType = item.categoryId;
        }
      });

      // this.resetFields();
    },
    // 点击进入跟踪详情
    goDetail(val) {
      console.log(val);
      this.$router.push({
        path: "/user/my-collect/customer/trackRecord-detail",
        query: {
          id: val,
          clientId: this.$route.query.id,
        },
      });
    },
    // 删除方法
    handleDel(val) {
      this.$confirm("删除后将不能撤回，是否删除?", "提示", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.personalCenter
            .deleteClientProject(this.ids.join(","))
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getClientProject();
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        })
        .catch(() => {});
    },
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.getClientProject();
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.form.resetFields();
      this.dataPO = {};
    },
    // 表单提交事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dataPO.projectId) {
            this.$api.personalCenter
              .updateClientProject({
                ...this.dataPO,
                clientId: this.$route.query.id,
              })
              .then((res) => {
                this.visible = false;
                this.$message.success("修改成功");
                this.dataPO = {};
                this.getClientProject();
              })
              .catch((msg) => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          } else {
            this.$api.personalCenter
              .addClientProject({
                ...this.dataPO,
                clientId: this.$route.query.id,
              })
              .then((res) => {
                this.visible = false;
                this.$message.success("新增成功");
                this.dataPO = {};
                this.getClientProject();
              })
              .catch((msg) => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          }
        } else {
          this.$message.error("提交失败!");
          return false;
        }
      });
    },
    // 表单取消
    cancel() {
      this.visible = false;
      // this.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
